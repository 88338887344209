import React from 'react'
import {
  DatePicker as MUIDatePicker,
  DatePickerProps as MUIDatePickerProps,
} from '@mui/x-date-pickers'
import moment, { MomentInput } from 'moment'
import datePickerRenderDay from './datePickerRenderDay'

type DatePickerProps = MUIDatePickerProps<moment.Moment, moment.Moment> & {
  markedDates?: MomentInput[]
}

const ReactMustBeInScope: React.FC = () => {
  return <></>
}

function DatePicker({
  disablePast = true,
  markedDates = [],
  maxDate,
  minDate,
  ...datePickerProps
}: DatePickerProps) {
  return (
    <MUIDatePicker
      {...datePickerProps}
      disablePast={disablePast}
      maxDate={maxDate}
      minDate={minDate}
      renderDay={datePickerRenderDay(markedDates)}
    />
  )
}

export default DatePicker
export { ReactMustBeInScope }
export type { DatePickerProps }
