import React, { useEffect } from 'react'
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { Skeleton } from '@mui/material'
import {
  DataGrid as MUIDataGrid,
  GridColDef,
  GridSortModel,
  GridValidRowModel,
} from '@mui/x-data-grid'
import { Page, PageRequestParams } from 'api/page'
import { UseQueryResult } from 'react-query'
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity'

type ExtraRequestParams<T = Record<string, unknown>> = T
type DataGridProps<T extends GridValidRowModel, R = Record<string, unknown>> = {
  dataId: string
  columns: GridColDef<T>[]
  initialState?: GridInitialStateCommunity
  requestExtraParams: ExtraRequestParams<R>
  sortModel?: GridSortModel
  useFetchPaginatedData: (
    request: PageRequestParams & ExtraRequestParams<R>
  ) => UseQueryResult<Page<T>, unknown>
  onDataLoad?: (pageData: Page<T>) => void
}

function DataGrid<
  T extends GridValidRowModel,
  RequestExtraParams = Record<string, unknown>
>({
  dataId,
  columns,
  initialState,
  requestExtraParams,
  sortModel,
  useFetchPaginatedData,
  onDataLoad = () => {},
}: DataGridProps<T, RequestExtraParams>): JSX.Element {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const page = parseInt(searchParams.get('page') ?? '1')
  const pageSize = parseInt(searchParams.get('size') ?? '5')
  const { isLoading, data: tableData } = useFetchPaginatedData({
    page,
    page_size: pageSize,
    ...requestExtraParams,
  })

  useEffect(() => {
    if (tableData) {
      onDataLoad(tableData)
    }
  }, [onDataLoad, tableData])

  const renavigate = (page: number, pageSize: number) => {
    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams({
        page: `${page + 1}`,
        size: `${pageSize}`,
      }).toString()}`,
    })
  }

  const handleChangePage = (page: number) => renavigate(page, pageSize)
  const handleChangePageSize = (pageSize: number) => renavigate(0, pageSize)

  return (
    <>
      {isLoading && (
        <Skeleton
          data-testid={`data-grid-${dataId}-loading`}
          variant="rectangular"
          width="100%"
          height={400}
          animation="wave"
          sx={{ marginBottom: 2 }}
        />
      )}

      {!isLoading && (
        <div
          data-test-id={`data-grid-${dataId}`}
          style={{ height: 'auto', width: '100%' }}
        >
          <MUIDataGrid<T>
            columns={columns}
            initialState={initialState}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangePageSize}
            rows={tableData?.entries ?? []}
            page={(tableData?.page ?? 1) - 1}
            pageSize={pageSize}
            pagination
            disableSelectionOnClick
            paginationMode="server"
            rowCount={tableData?.total_entries}
            rowsPerPageOptions={[5, 15, 100]}
            rowHeight={70}
            sortModel={sortModel}
            autoHeight
            sx={{
              boxShadow: 0,
              border: 0,
              borderColor: 'primary.light',
            }}
          />
        </div>
      )}
    </>
  )
}

export default DataGrid
