import React from 'react'
import { Grid, Stack } from '@mui/material'
import { VictoryPie } from 'victory'
import { theme, ContentHeader, Button } from 'flanders-common-ui'
import { FindInPage } from '@mui/icons-material'
import { sites } from 'routes/routes'
import { useNavigate, useParams } from 'react-router-dom'

type SiteHealthyDisplayProps = {
  totalLocationCount: number
}

const SiteHealthyDisplay: React.FC<SiteHealthyDisplayProps> = ({
  totalLocationCount,
}) => {
  const navigate = useNavigate()
  const { siteId = '' } = useParams<{ siteId: string }>()
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Stack alignItems={'center'} paddingBottom={4}>
          <svg width={300} height={300}>
            <VictoryPie
              style={{
                data: {
                  stroke: '#fff',
                  strokeWidth: 2,
                  boxSizing: 'border-box',
                },
                labels: {
                  opacity: 0,
                },
              }}
              innerRadius={120}
              colorScale={[
                theme.palette.success.main,
                theme.palette.error.main,
                theme.palette.info.main,
              ]}
              width={300}
              height={300}
              standalone={false}
              data={[{ x: 1, y: totalLocationCount, label: 'Healthy' }]}
            />
            <text
              x="50%"
              y="48%"
              textAnchor="middle"
              fontSize={50}
              fontWeight={700}
              fontFamily={theme.typography.fontFamily}
              fill={theme.palette.grey[800]}
              dy=".3em"
            >
              {totalLocationCount}
            </text>
            <text
              x="50%"
              y="58%"
              textAnchor="middle"
              fontSize={14}
              dy=".3em"
              fontWeight={600}
              fontFamily={theme.typography.fontFamily}
              fill={theme.palette.grey[600]}
            >
              TOTAL LOCATIONS
            </text>
          </svg>
          <ContentHeader
            title="Site Healthy!"
            body="All locations operational and passing tests"
          />
          <Button
            sx={{ marginBottom: 3 }}
            endIcon={<FindInPage />}
            onClick={() => {
              navigate(`${sites}/${siteId}/locations`)
            }}
          >
            View All Locations
          </Button>
          {/* TODO: Add Large Icon Once Added to the component lib */}
        </Stack>
      </Grid>
    </Grid>
  )
}

export default SiteHealthyDisplay
