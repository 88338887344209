import React from 'react'
import { PickersDayProps, PickersDay } from '@mui/x-date-pickers'
import { MomentInput } from 'moment'
import PickersDayScheduledTest from './PickersDayScheduledTest'

const ReactMustBeInScope: React.FC = () => {
  return <></>
}
const datePickerRenderDay =
  (markedDates: MomentInput[]) =>
  // eslint-disable-next-line react/display-name
  (
    day: moment.Moment,
    _selectedDay: moment.Moment[],
    pickersDayProps: PickersDayProps<moment.Moment>
  ): JSX.Element =>
    markedDates.find((date) => day.isSame(date, 'day')) ? (
      <PickersDayScheduledTest {...pickersDayProps} />
    ) : (
      <PickersDay {...pickersDayProps} />
    )

export default datePickerRenderDay
export { ReactMustBeInScope }
