import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { Paper, Stack } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { useFetchLocationsListPaginated } from 'api/locations'
import type { Location } from 'api/locations'
import {
  AboveFoldLayout,
  Fixture,
  Duration30SecondIcon,
  StatusChip,
  Duration90MinuteIcon,
  Typography,
} from 'flanders-common-ui'
import DataGrid from 'components/molecules/DataGrid'
import { getLocationNodeStatusChipProps } from '../../utils/getLocationNodeStatusChipProps'
import { formatDateOnly, formatTimeOnly } from 'utils/date-utils'
import TestStatusColumn from '../../components/organisms/tests/TestStatusColumn'
import { theme } from 'flanders-common-ui'
import { useActiveOrganizationContext } from 'hooks/useActiveOrganization'

function useColumns({
  siteId
}: {
  siteId: string
}): GridColDef<Location>[] {
  const columns: GridColDef<Location>[] = [
    {
      field: 'name',
      headerName: 'Location Name',
      flex: 2,
      renderCell: ({ row: location }) => {
        return (
          <NavLink
            key={location.id}
            to={`/sites/${siteId}/locations/${location.id}`}
            state={location}
          >
            {location.name}
          </NavLink>
        )
      },
    },
    {
      field: 'fixture.fixtureType',
      headerName: 'Fixture Type',
      flex: 1,
      renderCell: ({ row: location }) => {
        const fixtureType = location.fixture?.fixtureType

        const fixtureMarkup =
          fixtureType !== null && typeof fixtureType !== 'undefined' ? (
            <Fixture fixtureType={fixtureType} />
          ) : (
            <span>--</span>
          )

        return fixtureMarkup
      },
    },
    {
      field: 'nodeStatus',
      headerName: 'Status',
      flex: 1,
      valueGetter: (params) => params.row,
      renderCell: ({ row: location }) => {
        return (
          <StatusChip
            data-test-id={`location-node-status-chip-${location.id}`}
            {...getLocationNodeStatusChipProps(location)}
          />
        )
      },
    },
    {
      field: 'lastTest',
      headerName: 'Last Test Ran',
      flex: 1,
      renderCell: ({ row: location }) => {
        if (location.lastTest === null) {
          return <div>--</div>
        } else {
          return (
            <Stack flexDirection={'column'}>
              <Typography color={theme.palette.grey[900]}>
                {formatDateOnly(location.lastTest.startedAt)}
              </Typography>
              <Typography variant="bodySmall" color={theme.palette.grey[700]}>
                {formatTimeOnly(location.lastTest.startedAt)}
              </Typography>
            </Stack>
          )
        }
      },
    },
    {
      field: 'testType',
      headerName: 'Last Test Type',
      flex: 1,
      valueGetter: (params) => params.row.lastTest,
      renderCell: ({ row: location }) => {
        if (location.lastTest === null) {
          return <div>--</div>
        } else {
          return (
            <>
              {location.lastTest.testType === '30sec' ? (
                <Duration30SecondIcon sx={{ marginRight: 1 }} />
              ) : (
                <Duration90MinuteIcon sx={{ marginRight: 1 }} />
              )}
              <Typography variant="bodySmall">
                {location.lastTest.scheduledType === 'manual_location' ||
                location.lastTest.scheduledType === 'manual_sitewide'
                  ? 'Manual'
                  : 'Automatic'}
              </Typography>
            </>
          )
        }
      },
    },
    {
      field: 'lastTestResult',
      headerName: 'Last Test Result',
      flex: 1,
      valueGetter: (params) => params.row.lastTest,
      renderCell: ({ row: location }) => {
        if (location.lastTest === null) {
          return <div>--</div>
        } else {
          return (
            <TestStatusColumn
              testHistoryData={location.lastTest}
              testId={`location-last-test-result-status-chip-${location.id}`}
            />
          )
        }
      },
    },
  ]

  return columns
}

const LocationsPage: React.FC = () => {
  const { siteId = '' } = useParams<{
    siteId: string
  }>()
  const columns = useColumns({
    siteId: siteId
  })
  const { activeOrganization } = useActiveOrganizationContext()

  return (
    <>
      <AboveFoldLayout breadcrumbRoutes={[]} heading="Locations" />
      <Stack component={Paper} spacing={1} px={2} pt="20px">
        <Stack width="100%">
          
            <DataGrid<Location>
              columns={columns}
              dataId="locations-table"
              requestExtraParams={{
                organizationId: activeOrganization?.id,
              }}
              useFetchPaginatedData={useFetchLocationsListPaginated}
            />

        </Stack>
      </Stack>
    </>
  )
}

export default LocationsPage
