import React, { useEffect, useState } from 'react'
import {
  Button,
  ContentHeader,
  Select,
  SelectChangeEvent,
  TextField,
} from 'flanders-common-ui'
import { MenuItem, Stack } from '@mui/material'
import {
  isStateAbbreviation,
  stateAbbreviations,
  USStateAbbreviation,
} from 'utils/us-states'
import { Moment } from 'moment'
import moment from 'moment'
import { OrganizationPostData } from 'api/organizations'
import DatePicker from 'components/molecules/date-picker/DatePicker'

export type OrganizationEditProps = {
  address1?: string
  address2?: string
  anniversaryDate?: Moment | null
  handleClickDelete?: () => void
  handleClickSubmit: (organizationData: OrganizationPostData) => void
  headingText: string
  name?: string
  city?: string
  state?: USStateAbbreviation
  subtitleText: string
  postalCode?: string
}

const OrganizationEdit: React.FC<OrganizationEditProps> = ({
  address1 = '',
  address2 = '',
  anniversaryDate = null,
  city = '',
  handleClickDelete,
  handleClickSubmit,
  headingText,
  name = '',
  state = 'AL',
  postalCode = '',
  subtitleText,
}) => {
  const [nameDraft, setNameDraft] = useState(name)
  const [anniversaryDateDraft, setAnniversaryDateDraft] =
    useState(anniversaryDate)
  const [address1Draft, setAddress1Draft] = useState(address1)
  const [address2Draft, setAddress2Draft] = useState(address2)
  const [cityDraft, setCityDraft] = useState(city)
  const [stateDraft, setStateDraft] = useState(state)
  const [postalCodeDraft, setPostalCodeDraft] = useState(postalCode)

  const [isSaveDisabled, setIsSaveDisabled] = useState(true)

  const onSave = () => {
    handleClickSubmit({
      address1: address1Draft,
      address2: address2Draft,
      anniversaryDate: moment.utc(anniversaryDateDraft).toDate(),
      city: cityDraft,
      name: nameDraft,
      postalCode: postalCodeDraft,
      state: stateDraft,
    })
  }
  useEffect(() => {
    const isSaveEnabled =
      nameDraft?.trim().length &&
      moment.utc(anniversaryDateDraft).isValid() &&
      address1Draft?.trim().length &&
      cityDraft?.trim().length &&
      stateDraft?.trim().length &&
      postalCodeDraft?.trim().length

    setIsSaveDisabled(!isSaveEnabled)
  }, [
    nameDraft,
    anniversaryDateDraft,
    address1Draft,
    cityDraft,
    postalCodeDraft,
  ])

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameDraft(event.target.value)
  }

  const handleChangeAnniversaryDate = (value: Moment | null) => {
    const dateWithinTimeWindow = moment(value, 'MMM D, YYYY').hours(0)

    setAnniversaryDateDraft(dateWithinTimeWindow)
  }

  const handleChangeAddress1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress1Draft(event.target.value)
  }
  const handleChangeAddress2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress2Draft(event.target.value)
  }
  const handleChangeCity = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCityDraft(event.target.value)
  }
  const handleChangeState = (event: SelectChangeEvent<USStateAbbreviation>) => {
    if (isStateAbbreviation(event.target.value)) {
      setStateDraft(event.target.value)
    }
  }
  const handleChangePostalCode = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPostalCodeDraft(event.target.value)
  }

  return (
    <Stack alignItems="center" spacing={2} maxWidth={375} width="100%">
      <ContentHeader title={headingText} body={subtitleText} />

      <TextField
        autoFocus
        fullWidth
        id="name"
        label="Name"
        margin="dense"
        value={nameDraft}
        onChange={handleChangeName}
        variant="standard"
      />

      <DatePicker
        disablePast={false}
        label="Billing Anniversary Date"
        onChange={handleChangeAnniversaryDate}
        renderInput={(props) => (
          <TextField
            {...props}
            style={{
              width: '100%',
            }}
          />
        )}
        value={anniversaryDateDraft}
      />

      <TextField
        fullWidth
        id="billing-address"
        label="Billing Address"
        margin="dense"
        value={address1Draft}
        onChange={handleChangeAddress1}
        variant="standard"
      />

      <TextField
        fullWidth
        id="billing-address-2"
        label="Billing Address 2"
        margin="dense"
        value={address2Draft}
        onChange={handleChangeAddress2}
        variant="standard"
      />

      <TextField
        fullWidth
        id="city"
        label="City"
        margin="dense"
        onChange={handleChangeCity}
        value={cityDraft}
        variant="standard"
      />

      <Select
        fieldLabel="State"
        onChange={handleChangeState}
        id="select-state"
        name="State"
        value={stateDraft}
      >
        {stateAbbreviations.map((state) => (
          <MenuItem key={state} value={state}>
            {state}
          </MenuItem>
        ))}
      </Select>

      <TextField
        fullWidth
        id="postal-code"
        label="Postal Code"
        margin="dense"
        maxLength={5}
        onChange={handleChangePostalCode}
        value={postalCodeDraft}
        variant="standard"
      />

      <Stack spacing={2} width="100%">
        <Button disabled={isSaveDisabled} onClick={onSave} size="large">
          Submit
        </Button>
        {handleClickDelete && (
          <Button
            onClick={handleClickDelete}
            size="large"
            variant="text"
            color="error"
          >
            Delete Organization
          </Button>
        )}
      </Stack>
    </Stack>
  )
}

export default OrganizationEdit
