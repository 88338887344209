import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import UserEdit, { UserEditProps } from 'components/organisms/forms/UserEdit'
import { Paper, Stack } from '@mui/material'
import UserInviteSent from './UserInviteSent'
import { emcAdminUsers, users } from 'routes/routes'
import { postUser } from 'api/users/postUser'
import { Role } from 'api/users'
import { useToastAlertProvider } from 'flanders-common-ui'
import { useAuthenticatedUser } from 'hooks/useAuth'
import { useActiveOrganizationContext } from 'hooks/useActiveOrganization'

const UserInviteSend: React.FC = () => {
  const [inviteSent, setInviteSent] = useState(false)

  const navigate = useNavigate()
  const user = useAuthenticatedUser()
  const { setToast } = useToastAlertProvider()

  const { activeOrganization } = useActiveOrganizationContext()

  const onSubmit = async ({
    nameFirst,
    nameLast,
    email,
    role,
  }: {
    nameFirst: string
    nameLast: string
    email: string
    role: Role
  }) => {
    if (activeOrganization?.id) {
      const response = await postUser({
        email: email,
        nameFirst: nameFirst,
        nameLast: nameLast,
        organizationId: activeOrganization?.id,
        role: role,
      }).catch((error) => {
        const toastMessage = error?.email?.includes('has already been taken')
          ? 'Error: Email already taken'
          : 'User Invite Send Failed'
        setToast({ message: toastMessage, severity: 'error' })
      })

      if (response?.id) {
        setToast({ message: 'User created successfully', severity: 'success' })
        setInviteSent(true)
      } else {
        console.error('API returned no user ID')
      }
    } else {
      console.error('No user organization')
    }
  }

  const isEmcAdminUserExperience = (user.role === 'emc-admin') && (activeOrganization?.id === user.organizationId)
  const commonEditData = {
    email: '',
    handleClickSave: onSubmit,
    headingText: 'Add New User',
    nameFirst: '',
    nameLast: '',
    subtitleText: `Add a new user to ${activeOrganization?.name ?? 'your organization'}`,
  }
  const userEditData: UserEditProps = isEmcAdminUserExperience ? 
    {
      ...commonEditData,
      role: 'emc-admin',
      disabledFields: { role: true },
    } : {
      ...commonEditData,
      role: 'viewer',
    }

  return (
    <Stack
      data-test-id="user-invite-send-container"
      spacing={1}
      p={1}
      flexGrow={1}
    >
      <Paper
        sx={{
          py: 8,
          px: 2,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          alignItems: 'center',
          width: '100%',
          flexGrow: 1,
          justifyContent: 'center',
        }}
      >
        {inviteSent ? (
          <UserInviteSent
            handleClickFinish={() => {
              navigate(isEmcAdminUserExperience ? emcAdminUsers : users)
            }}
            handleClickSecondary={() => setInviteSent(false)}
          />
        ) : (
          <UserEdit {...userEditData} />
        )}
      </Paper>
    </Stack>
  )
}

export default UserInviteSend
