import React from 'react'
import { StatusChart, TableBanner, theme, Typography } from 'flanders-common-ui'
import { GridColDef, gridStringOrNumberComparator } from '@mui/x-data-grid'
import CancelIcon from '@mui/icons-material/CancelOutlined'
import { NavLink, useNavigate } from 'react-router-dom'
import DataGrid from 'components/molecules/DataGrid'
import { Paper, Skeleton, Stack } from '@mui/material'
import { Site, useFetchSitesListPaginated } from 'api/sites'
import { useFetchOrganizationOverviewById } from 'api/organizations'
import { useAuth } from 'hooks/useAuth'
import { useActiveOrganizationContext } from 'hooks/useActiveOrganization'
import { forbidden } from 'routes/routes'
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline'
import { formatDate } from 'utils/date-utils'

type ColumnStatusParams = {
  statusDetails: React.ReactNode
  statusHeading: React.ReactNode
  isError: boolean
}
function ColumnStatus({
  isError,
  statusDetails,
  statusHeading,
}: ColumnStatusParams) {
  return (
    <Stack
      width={'100%'}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack>
        <Typography
          color={isError ? theme.palette.error.main : theme.palette.info.main}
          weight={'semiBold'}
          marginBottom={1}
          variant="body"
        >
          {statusHeading}
        </Typography>
        <Typography
          color={theme.palette.grey[400]}
          fontStyle="italic"
          variant="body"
        >
          {statusDetails}
        </Typography>
      </Stack>
      { isError ? (
        <CancelIcon
          color="error"
          data-testid="cancel-icon"
          width={24}
          height={24}
        />
      ) : (
        <CheckCircleIcon
          color="success"
          data-testid="check-icon"
          width={24}
          height={24}
        />
      )}
    </Stack>
  )
}
function useColumns(): GridColDef<Site>[] {
  const columns: GridColDef<Site>[] = [
    {
      field: 'name',
      headerName: 'Site Name',
      flex: 3,
      renderCell: ({ row: site }) => {
        return (
          <NavLink key={site.id} to={`/sites/${site.id}`} state={site}>
            {site.name}
          </NavLink>
        )
      },
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 1,
      valueGetter: ({ row: site }) => `${site.city}, ${site.state}`,
    },
    {
      field: 'locationCount',
      headerName: 'Locations',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 2,
      sortComparator: gridStringOrNumberComparator,
      renderCell: ({ row: site }) => {
        let columnStatusData
        if (site.status === 'gateway_offline') {
          columnStatusData = {
            isError: true,
            statusDetails: <>Last Heartbeat: {formatDate(site.lastHeartbeatAt)}</>,
            statusHeading: <>Gateway Offline</>,
          }
        } else if (site.status === 'requires_attention' && site.requiresAttentionCount > 0) {
          columnStatusData = {
            isError: true,
            statusDetails: <>Require Attention</>,
            statusHeading: <>{site.requiresAttentionCount} Locations</>,
          }
        } else if (site.status === 'gateway_online' && site.requiresAttentionCount === 0) {
          columnStatusData = {
            isError: false,
            statusDetails: 'Healthy',
            statusHeading: 'Gateway Online'
          }
        } else {
          columnStatusData = {
            isError: true,
            statusDetails: site.status,
            statusHeading: <>{site.requiresAttentionCount} Require Attention</>
          }
        }

        return <ColumnStatus {...columnStatusData} />
      },
    },
  ]

  return columns
}

const DashboardPage: React.FC = () => {
  const columns = useColumns()
  const { user } = useAuth()
  const { activeOrganization } = useActiveOrganizationContext()
  const navigate = useNavigate()

  if (!user) {
    navigate(forbidden, { replace: true })
    // Resolves TS thinking user is undefined
    throw new Error('User Undefined')
  }

  const { isLoading: isLoadingOverview, data: overviewData } =
    useFetchOrganizationOverviewById(
      activeOrganization?.id ?? user.user.organizationId
    )

  const { healthy = 0, requiringAttention = 0 } = overviewData ?? {}
  const totalCount = healthy + requiringAttention

  if (isLoadingOverview) {
    return (
      <Skeleton
        variant="rectangular"
        width="100%"
        height={400}
        animation="wave"
        data-testid="dashboard-loading-skeleton"
      />
    )
  }

  return (
    <Stack component={Paper} spacing={1} px={2} pt="20px">
      <StatusChart
        healthy={healthy}
        requireAttention={requiringAttention}
        running={0}
        targetName="SITES"
        totalCount={totalCount}
      />

      <Stack width="100%">
        <TableBanner
          requireAttentionCount={requiringAttention}
          targetNamePlural="Sites"
          targetNameSingular="Site"
        />
        <DataGrid<Site>
          columns={columns}
          dataId="sites-requiring-attention-table"
          requestExtraParams={{
            organizationId: activeOrganization?.id,
            requiringAttention: true,
          }}
          useFetchPaginatedData={useFetchSitesListPaginated}
        />
      </Stack>
    </Stack>
  )
}

export default DashboardPage
