import { apiClient } from 'api/http-common'
import type { APIFunction, GenericErrorResponse } from 'api/http-common'
import { mapOrganizationResponseToOrganization } from './mapOrganizationResponseToOrganization'
import { Organization, OrganizationResponse } from './organization-types'

export const deleteOrganization: APIFunction<string, Organization> = async (
  id
) => {
  try {
    const response = await apiClient.delete<OrganizationResponse>(
      `/organizations/${id}`
    )

    return mapOrganizationResponseToOrganization(response.data)
  } catch (err: unknown) {
    const axiosError = err as GenericErrorResponse
    const { data } = axiosError.response ?? {}

    throw data?.message ?? []
  }
}
