import { useQuery } from 'react-query'
import {
  fetchUsersListPaginated,
  UsersListRequestParams,
} from './fetchUsersListPaginated'

export const useFetchUsersListPaginated = (
  pageRequestParams: UsersListRequestParams
) => {
  return useQuery(
    ['fetchUsersListPaginated', pageRequestParams],
    fetchUsersListPaginated(pageRequestParams)
  )
}
