import { apiClient } from 'api/http-common'
import type { APIFunction, GenericErrorResponse } from 'api/http-common'
import { mapOrganizationResponseToOrganization } from './mapOrganizationResponseToOrganization'
import {
  Organization,
  OrganizationPostData,
  OrganizationResponse,
} from './organization-types'

export const postOrganization: APIFunction<
  OrganizationPostData,
  Organization
> = async ({
  address1,
  address2,
  anniversaryDate,
  city,
  name,
  postalCode,
  state,
}) => {
  try {
    const response = await apiClient.post<OrganizationResponse>(
      `/organizations`,
      {
        anniversary_date: anniversaryDate,
        address_1: address1,
        address_2: address2,
        city: city,
        name: name,
        postal_code: postalCode,
        state: state,
      }
    )

    return mapOrganizationResponseToOrganization(response.data)
  } catch (err: unknown) {
    const axiosError = err as GenericErrorResponse
    const { data } = axiosError.response ?? {}

    throw data?.message ?? []
  }
}
