import { mapUserResponseToUser } from './mapUserResponseToUser'
import { UserTokens, UserTokensResponse } from './users-types'

export const mapUserTokensResponseToUserTokens = (
  userTokensResponse: UserTokensResponse
): UserTokens => ({
  accessToken: userTokensResponse.access_token,
  refreshToken: userTokensResponse.refresh_token,
  user: mapUserResponseToUser(userTokensResponse.user),
})
