import React, { useEffect, useState } from 'react'
import { AboveFoldLayout, useToastAlertProvider } from 'flanders-common-ui'
import { useNavigate, useParams } from 'react-router-dom'
import {
  deleteOrganizationSite,
  patchOrganizationSiteGatewayId,
  putOrganizationSite,
  Site,
  useFetchSiteById,
} from 'api/sites'
import { organizations } from 'routes/routes'
import { Stack, Paper } from '@mui/material'
import OrganizationSiteDetailsForm from './OrganizationSiteDetailsForm'
import { useActiveOrganizationContext } from 'hooks/useActiveOrganization'

const OrganizationSitesEditSitePage: React.FC = () => {
  const navigate = useNavigate()
  const { activeOrganization } = useActiveOrganizationContext()
  const { organizationId = '', siteId = '' } = useParams<{
    organizationId: string
    siteId: string
  }>()
  const { isLoading, data: site } = useFetchSiteById({ organizationId, siteId })
  const [siteDetailsDraft, setSiteDetailsDraft] = useState<Partial<Site>>({
    id: siteId,
  })
  const organizationName = activeOrganization?.name ?? ''
  const { setToast } = useToastAlertProvider()

  useEffect(() => {
    if (site) {
      setSiteDetailsDraft(site)
    }
  }, [site, siteId])

  const handleClickSubmit = async (updatedSiteData: Partial<Site>) => {
    try {
      const siteResponse = await putOrganizationSite({
        id: siteId,
        organizationId,
        ...updatedSiteData,
      })

      if (siteResponse.id) {
        setSiteDetailsDraft((prevData) => ({ ...prevData, ...updatedSiteData }))
        setToast({
          message: 'Site updated successfully',
          severity: 'success',
        })
        navigate(`${organizations}/${organizationId}/sites`)
      } else {
        setSiteDetailsDraft((prevData) => ({ ...prevData }))
        setToast({ message: 'Failed to update Site', severity: 'error' })
      }
    } catch (error) {
      console.error('Failed to update Site', error)
      // Reset the data so the previous Site data shows up on the form
      setSiteDetailsDraft((prevData) => ({ ...prevData }))
      setToast({ message: 'Failed to update Site', severity: 'error' })
    }
  }

  const handleClickUpdateGatewayId = async (gatewayId: string) => {
    try {
      const siteResponse = await patchOrganizationSiteGatewayId({
        organizationId,
        siteId,
        gatewayId,
      })
      if (siteResponse.id) {
        setSiteDetailsDraft((prevData) => ({ ...prevData, gatewayId }))
        setToast({
          message: 'Gateway ID updated successfully',
          severity: 'success',
        })
      } else {
        setSiteDetailsDraft((prevData) => ({ ...prevData }))
        setToast({ message: 'Failed to update Gateway ID', severity: 'error' })
      }
    } catch (error) {
      console.error('Failed to update Gateway ID', error)
      // Reset the data so the previous gateway ID shows up on the form
      setSiteDetailsDraft((prevData) => ({ ...prevData }))
      setToast({ message: 'Failed to update Gateway ID', severity: 'error' })
    }
  }

  const handleClickDelete = async () => {
    try {
      const siteResponse = await deleteOrganizationSite({
        organizationId,
        siteId,
      })
      if (siteResponse.id) {
        setToast({
          message: 'Site deleted successfully',
          severity: 'success',
        })
        navigate(`${organizations}/${organizationId}/sites`)
      } else {
        setToast({ message: 'Failed to delete Site', severity: 'error' })
      }
    } catch (error) {
      console.error('Failed to delete Site', error)
      setSiteDetailsDraft((prevData) => ({ ...prevData }))
      setToast({ message: 'Failed to delete Site', severity: 'error' })
    }
  }

  return isLoading ? (
    <>Loading...</>
  ) : (
    <>
      <AboveFoldLayout breadcrumbRoutes={[]} heading="Edit Site" />
      <Stack alignItems="center" component={Paper} pb={'48px'}>
        <OrganizationSiteDetailsForm
          variant="edit"
          siteData={siteDetailsDraft}
          organizationName={organizationName}
          onClickSubmit={handleClickSubmit}
          onClickDelete={handleClickDelete}
          onClickSubmitGatewayId={handleClickUpdateGatewayId}
        />
      </Stack>
    </>
  )
}

export default OrganizationSitesEditSitePage
