import React from 'react'
import { Stack } from '@mui/material'
import { theme, Typography } from 'flanders-common-ui'
import CancelIcon from '@mui/icons-material/CancelOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline'

type StatusProps = {
  description?: string
  showCheckIcon?: boolean
  status: string
}

const Status: React.FC<StatusProps> = ({
  showCheckIcon = false,
  description = '',
  status,
}: StatusProps) => {
  return (
    <Stack
      width="100%"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack marginRight={1}>
        <Typography
          color={
            showCheckIcon ? theme.palette.grey[800] : theme.palette.error.main
          }
          weight={showCheckIcon ? 'regular' : 'semiBold'}
          marginBottom={1}
          variant="body"
        >
          {status}
        </Typography>
        {description && (
          <Typography
            color={theme.palette.grey[400]}
            fontStyle="italic"
            variant="body"
          >
            {description}
          </Typography>
        )}
      </Stack>

      {showCheckIcon && (
        <CheckCircleIcon
          color="success"
          data-testid="check-icon"
          width={24}
          height={24}
        />
      )}

      {!showCheckIcon && (
        <CancelIcon
          color="error"
          data-testid="cancel-icon"
          width={24}
          height={24}
        />
      )}
    </Stack>
  )
}

export default Status
export type { StatusProps }
