import { Node } from 'api/nodes'
import { isNodeStatus } from 'api/nodes/node-types'
import { TestHistory, TestHistoryResponse } from 'api/tests/test-types'
import type {
  Location,
  LocationFixture,
  LocationResponse,
  LocationResponseFixture,
} from './location-types'

const formatNodeData = (rawNodeData: Node) => {
  let nodeData: Node | null = null

  if (rawNodeData.status !== null) {
    // Reformat until API is updated to lowercase
    const nodeStatusFormatted = rawNodeData.priority_status
      ? rawNodeData.priority_status.toLowerCase()
      : rawNodeData.status.toLowerCase()

    if (isNodeStatus(nodeStatusFormatted)) {
      nodeData = {
        ...rawNodeData,
        status: nodeStatusFormatted,
        priority_status: nodeStatusFormatted,
      }
    } else if (nodeStatusFormatted !== null) {
      console.error(
        `Unknown node status: ${rawNodeData.priority_status.toLowerCase()}`
      )
    }
  }

  return nodeData
}

const formatLastTestData = (
  rawLastTestData: TestHistoryResponse
): TestHistory => {
  const formattedLastTestData: TestHistory = {
    ...rawLastTestData,
    rescheduledFor: rawLastTestData.rescheduled_for,
    sensorResults: rawLastTestData.sensor_results,
    startedAt: rawLastTestData.started_at,
    testType: rawLastTestData.test_type,
    scheduledType: rawLastTestData.scheduled_type,
  }

  return formattedLastTestData
}

const formatFixtureData = (
  rawFixtureData: LocationResponseFixture
): LocationFixture => {
  return {
    batteryModelNumber: rawFixtureData.battery_model_number,
    fixtureModelNumber: rawFixtureData.fixture_model_number,
    fixtureType: rawFixtureData.fixture_type,
    voltage: rawFixtureData.voltage,
  }
}

export const mapLocationResponseToLocation = (
  locationResponse: LocationResponse
): Location => {
  const nodeData =
    locationResponse.node !== null
      ? formatNodeData(locationResponse.node)
      : null
  const lastTestData =
    !!locationResponse.last_test && locationResponse.last_test !== null
      ? formatLastTestData(locationResponse.last_test)
      : null

  const fixtureData = locationResponse.fixture
    ? formatFixtureData(locationResponse.fixture)
    : null

  return {
    ...locationResponse,
    fixture: fixtureData,
    lastTest: lastTestData,
    node: nodeData,
    organizationId: locationResponse.organization_id,
    testResults: locationResponse.test_results,
    visuallyVerifiedAt: locationResponse.visually_verified_at,
  }
}
