import React, { useState } from 'react'
import { Stack } from '@mui/material'
import { ContentHeader, Button } from 'flanders-common-ui'
import { GatewayIdInput } from './GatewayIdInput'

export type OrganizationSiteGatewayIdFormProps = {
  gatewayId?: string
  onClickBack: (gatewayId: string) => void
  onClickSubmit: (gatewayId: string) => void
}

const OrganizationSiteGatewayIdForm: React.FC<
  OrganizationSiteGatewayIdFormProps
> = ({ gatewayId, onClickBack, onClickSubmit }) => {
  const [draftGatewayId, setDraftGatewayId] = useState(gatewayId ?? '')

  const handleClickSubmit = () => {
    if (draftGatewayId.length) {
      onClickSubmit(draftGatewayId)
    }
  }

  const handleClickBack = () => {
    onClickBack(draftGatewayId)
  }

  return (
    <Stack spacing={2} maxWidth={375} width="100%">
      <ContentHeader
        title="Enter Gateway ID"
        body="It's crucial that the Local Gateway ID is correct as it links the site's local gateway data to this site in the cloud."
      />
      <GatewayIdInput value={draftGatewayId} onChange={setDraftGatewayId} />
      <Button
        disabled={draftGatewayId.length !== 6}
        onClick={handleClickSubmit}
        size="large"
      >
        Submit
      </Button>
      <Button onClick={handleClickBack} size="large" variant="text">
        Back
      </Button>
    </Stack>
  )
}

export default OrganizationSiteGatewayIdForm
