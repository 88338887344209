import { UpcomingTestResponse, UpcomingTest } from './test-types'

export const mapUpcomingTestResponseToUpcomingTest: (
  upcomingTestResponse: UpcomingTestResponse
) => UpcomingTest = (upcomingTestResponse) => ({
  id: upcomingTestResponse.id,
  isAutoScheduled: upcomingTestResponse.is_auto_scheduled,
  isSiteWide: upcomingTestResponse.is_site_wide,
  processingEndedAt: upcomingTestResponse.processing_ended_at,
  processingStartedAt: upcomingTestResponse.processing_started_at,
  scheduledFor: upcomingTestResponse.scheduled_for,
  testType: upcomingTestResponse.test_type,
})
