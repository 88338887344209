import { apiClient } from 'api/http-common'
import type { APIFunction, GenericErrorResponse } from 'api/http-common'
import type { Role, RoleResponse, User, UserResponse } from './users-types'
import { mapUserResponseToUser } from './mapUserResponseToUser'

type PostUserRequestParams = {
  email: string
  nameFirst: string
  nameLast: string
  organizationId: string
  role: Role
}

export const postUser: APIFunction<PostUserRequestParams, User> = async ({
  email,
  nameFirst,
  nameLast,
  organizationId,
  role,
}) => {
  //Convert client-side role to server-side role
  const mappedRole: RoleResponse = role === 'emc-admin' ? 'emc_admin' : role

  try {
    const response = await apiClient.post<UserResponse>(`users/invite`, {
      user: {
        email,
        first_name: nameFirst,
        last_name: nameLast,
        organization_id: organizationId,
        role: mappedRole,
      },
    })

    return mapUserResponseToUser(response.data)
  } catch (err: unknown) {
    const axiosError = err as GenericErrorResponse
    const { data } = axiosError.response ?? {}

    throw data?.message ?? []
  }
}
