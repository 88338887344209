import { apiClient } from 'api/http-common'
import type { APIFunction, GenericErrorResponse } from 'api/http-common'
import { OrganizationSitePostData } from 'api/organizations'
import { Site, SiteResponse } from './sites-types'
import { mapSiteResposeToSite } from './mapSiteResposeToSite'

export const postOrganizationSite: APIFunction<
  OrganizationSitePostData,
  Site
> = async ({
  address1,
  address2,
  city,
  emcInternalReferenceNumber,
  gatewayId,
  name,
  organizationId,
  state,
  zipcode,
}) => {
  try {
    const response = await apiClient.post<SiteResponse>(
      `organizations/${organizationId}/sites`,
      {
        address_1: address1,
        address_2: address2,
        city: city,
        emc_internal_reference_number: emcInternalReferenceNumber,
        gateway_id: gatewayId,
        name: name,
        state: state,
        postal_code: zipcode,
      }
    )

    return mapSiteResposeToSite(response.data)
  } catch (err: unknown) {
    const axiosError = err as GenericErrorResponse
    const { data } = axiosError.response ?? {}

    throw data?.message ?? []
  }
}
