import { apiClient } from 'api/http-common'
import type { GenericErrorResponse } from 'api/http-common'

export const deleteAccessToken = async () => {
  try {
    const response = await apiClient.delete('/log_out')
    return response.data
  } catch (err: unknown) {
    const axiosError = err as GenericErrorResponse
    const { data } = axiosError.response ?? {}

    throw data?.message ?? ['Error logging out']
  }
}

export default deleteAccessToken
