import { useQuery } from 'react-query'
import {
  fetchSiteOverviewById,
  SiteOverviewByIdRequestParams,
} from 'api/locations'
import type { SiteOverviewResponse } from 'api/locations'

export const useFetchSiteOverviewById = (
  params: SiteOverviewByIdRequestParams
) => {
  return useQuery<SiteOverviewResponse, string[]>(
    ['fetchSiteOverviewById', params],
    fetchSiteOverviewById(params)
  )
}
