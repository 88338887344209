import React, { useEffect, useState } from 'react'
import { Stack, MenuItem, IconButton, InputAdornment } from '@mui/material'
import { Site } from 'api/sites'
import {
  ContentHeader,
  TextField,
  Select,
  Button,
  Dialog,
} from 'flanders-common-ui'
import {
  USStateAbbreviation,
  isStateAbbreviation,
  stateAbbreviations,
} from 'utils/us-states'
import { ModeEdit } from '@mui/icons-material'
import { GatewayIdInput } from './GatewayIdInput'

export type OrganizationSiteDetailsFormProps = {
  organizationName: string
  siteData?: Partial<Site>
  variant: 'create' | 'edit'
  onClickSubmit: (siteDetails: Partial<Site>) => void
  onClickDelete?: (siteDetails: Partial<Site>) => void
  onClickSubmitGatewayId?: (gatewayId: string) => Promise<void>
}

const OrganizationSiteDetailsForm: React.FC<
  OrganizationSiteDetailsFormProps
> = ({
  organizationName,
  siteData,
  variant,
  onClickSubmit,
  onClickDelete = () => {},
  onClickSubmitGatewayId = () => Promise.resolve(),
}) => {
  const [siteName, setSiteName] = useState(siteData?.name ?? '')
  const [emcInternalReferenceNumber, setEmcInternalReferenceNumber] = useState(
    siteData?.emcInternalReferenceNumber ?? ''
  )
  const [address, setAddress] = useState(siteData?.address ?? '')
  const [addressSecond, setAddressSecond] = useState(
    siteData?.addressSecond ?? ''
  )
  const [city, setCity] = useState(siteData?.city ?? '')
  const [state, setState] = useState<USStateAbbreviation>(
    siteData?.state ?? 'AL'
  )
  const [postalCode, setPostalCode] = useState(siteData?.postalCode ?? '')
  const [draftGatewayId, setDraftGatewayId] = useState(
    siteData?.gatewayId ?? ''
  )
  const [showGatewayIdDialog, setShowGatewayIdDialog] = useState(false)
  const [isGatewayIdSubmitting, setIsGatewayIdSubmitting] = useState(false)

  useEffect(() => {
    if (siteData) {
      setSiteName(siteData.name ?? '')
      setEmcInternalReferenceNumber(siteData.emcInternalReferenceNumber ?? '')
      setAddress(siteData.address ?? '')
      setAddressSecond(siteData.addressSecond ?? '')
      setCity(siteData.city ?? '')
      setState(siteData.state ?? 'AL')
      setPostalCode(siteData.postalCode ?? '')
      setDraftGatewayId(siteData.gatewayId ?? '')
    }
  }, [siteData])

  const isSubmitDisabled =
    !siteName ||
    !emcInternalReferenceNumber ||
    !address ||
    !city ||
    !state ||
    !postalCode

  const handleClickSubmit = () => {
    if (!isSubmitDisabled) {
      onClickSubmit({
        name: siteName,
        emcInternalReferenceNumber,
        address,
        addressSecond,
        city,
        state,
        postalCode: postalCode,
      })
    }
  }

  const handleClickDelete = () => {
    onClickDelete({
      name: siteName,
      emcInternalReferenceNumber,
      address,
      addressSecond,
      city,
      state,
      postalCode: postalCode,
    })
  }

  const handleGatewayIdSubmit = async () => {
    setIsGatewayIdSubmitting(true)
    await onClickSubmitGatewayId(draftGatewayId)
    setIsGatewayIdSubmitting(false)
    setShowGatewayIdDialog(false)
  }

  return (
    <Stack spacing={2} maxWidth={375} width="100%">
      <ContentHeader
        title={variant === 'edit' ? 'Edit Site' : 'Add New Site'}
        body={
          variant === 'edit' ? (
            <>
              Modify{' '}
              <strong>
                {organizationName}&apos;s {siteName}
              </strong>{' '}
              Site Information
            </>
          ) : (
            <>
              Create a new site for <strong>{organizationName}</strong>
            </>
          )
        }
      />

      <TextField
        autoFocus
        fullWidth
        id="site-name"
        label="Site Name"
        margin="dense"
        value={siteName}
        onChange={(event) => setSiteName(event.target.value)}
        variant="standard"
      />

      <TextField
        fullWidth
        id="site-emc-internal-reference-number"
        label="EMC Internal Reference Number"
        margin="dense"
        value={emcInternalReferenceNumber}
        onChange={(event) => setEmcInternalReferenceNumber(event.target.value)}
        variant="standard"
      />

      <TextField
        fullWidth
        id="site-address"
        label="Address"
        margin="dense"
        value={address}
        onChange={(event) => setAddress(event.target.value)}
        variant="standard"
      />

      <TextField
        fullWidth
        id="site-address-2"
        label="Address 2"
        margin="dense"
        value={addressSecond}
        onChange={(event) => setAddressSecond(event.target.value)}
        variant="standard"
      />

      <TextField
        fullWidth
        id="site-emc-city"
        label="City"
        margin="dense"
        value={city}
        onChange={(event) => setCity(event.target.value)}
        variant="standard"
      />

      <Select
        fieldLabel="State"
        onChange={(event) => {
          if (isStateAbbreviation(event.target.value)) {
            setState(event.target.value)
          }
        }}
        id="site-state"
        value={state}
      >
        {stateAbbreviations.map((stateAbbreviation) => (
          <MenuItem key={stateAbbreviation} value={stateAbbreviation}>
            {stateAbbreviation}
          </MenuItem>
        ))}
      </Select>

      <TextField
        fullWidth
        id="site-postal-code"
        label="Postal Code"
        margin="dense"
        maxLength={5}
        onChange={(event) => setPostalCode(event.target.value)}
        value={postalCode}
        variant="standard"
      />

      {variant === 'edit' && (
        <TextField
          fullWidth
          id="site-gateway-id"
          label="Gateway ID"
          margin="dense"
          value={draftGatewayId}
          onChange={() => {}} // If no onChange is provided, react throws: "Warning: A component is changing a controlled input to be uncontrolled"
          disabled
          variant="standard"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="update gateway id"
                  data-testid="update-gateway-id"
                  edge="end"
                  size="medium"
                  style={{ marginRight: 1 }}
                  onClick={() => {
                    setShowGatewayIdDialog(true)
                  }}
                >
                  <ModeEdit sx={{ width: 20, height: 20 }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}

      {variant === 'create' && (
        <Button
          disabled={isSubmitDisabled}
          onClick={handleClickSubmit}
          size="large"
        >
          Next
        </Button>
      )}

      {variant === 'edit' && (
        <>
          <Button
            disabled={isSubmitDisabled}
            onClick={handleClickSubmit}
            size="large"
          >
            Submit
          </Button>
          <Button
            onClick={handleClickDelete}
            size="large"
            variant="text"
            color="error"
          >
            Delete Site
          </Button>

          <Dialog
            isOpen={showGatewayIdDialog}
            subtitle="It's crucial that the Local Gateway ID is correct as it links the site's local gateway data to this site in the cloud."
            title="Update Gateway ID"
            handleClickCancel={() => {
              setShowGatewayIdDialog(false)
              setDraftGatewayId(siteData?.gatewayId ?? '')
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Stack spacing={2} maxWidth={375}>
                <GatewayIdInput
                  value={draftGatewayId}
                  onChange={setDraftGatewayId}
                />
                <Button
                  disabled={
                    draftGatewayId.length !== 6 || isGatewayIdSubmitting
                  }
                  onClick={handleGatewayIdSubmit}
                  size="large"
                >
                  Submit
                </Button>
              </Stack>
            </div>
          </Dialog>
        </>
      )}
    </Stack>
  )
}

export default OrganizationSiteDetailsForm
