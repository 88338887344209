import React from 'react'
import { capitalize } from '@mui/material'
import { formatDate } from 'utils/date-utils'
import WarningIcon from '@mui/icons-material/WarningOutlined'

export type ComplianceReportRowProps = {
  locationOdd: boolean
  locationName: string
  requiresAttention: boolean
  scheduledType: string
  startedAt: string
  testType: string
  status: string
}

const ComplianceReportRow: React.FC<ComplianceReportRowProps> = ({
  locationOdd,
  locationName,
  requiresAttention,
  scheduledType,
  startedAt,
  status,
  testType,
}) => {
  const testDurationMarkup = testType === '30sec' ? '30 Seconds' : '90 Minutes'
  const resultLabel = capitalize(status)
  const requiresAttentionIndicator = requiresAttention ? (
    <WarningIcon style={{ width: 24, height: 24 }} />
  ) : (
    ''
  )

  return (
    <tr
      className={locationOdd ? 'location-odd' : 'location-even'}
      data-testid={locationOdd ? 'location-odd' : 'location-even'}
    >
      <td>{locationName}</td>
      <td>{formatDate(startedAt)}</td>
      <td>{capitalize(scheduledType)}</td>
      <td>{testDurationMarkup}</td>
      <td>{resultLabel}</td>
      <td
        style={{
          paddingBottom: 0,
          paddingTop: 0,
          textAlign: 'right',
          verticalAlign: 'bottom',
        }}
      >
        {requiresAttentionIndicator}
      </td>
    </tr>
  )
}

export default ComplianceReportRow
