import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  PasswordField,
  Select,
  SelectChangeEvent,
  TextField,
  ContentHeader,
} from 'flanders-common-ui'
import { IconButton, InputAdornment, MenuItem, Stack } from '@mui/material'
import { isRoleType, Role, User } from 'api/users'
import PasswordEdit from './PasswordEdit'
import { ModeEdit } from '@mui/icons-material'
import {
  PasswordValidations,
  validateEmail,
  validatePassword,
} from 'utils/validate-utils'
import { useActiveOrganizationContext } from 'hooks/useActiveOrganization'
import { useAuthenticatedUser } from 'hooks/useAuth'

type SaveUserParams = Pick<User, 'email' | 'nameFirst' | 'nameLast' | 'role'>

export type UserEditProps = {
  disabledFields?: {
    nameFirst?: boolean
    nameLast?: boolean
    email?: boolean
    role?: boolean
  }
  email: string
  handleClickDelete?: () => void
  handleClickSave: ({
    nameFirst,
    nameLast,
    email,
    role,
  }: SaveUserParams) => void
  headingText: string
  isPasswordValid?: typeof validatePassword
  nameFirst: string
  nameLast: string
  role: Role
  showDeleteButton?: boolean
  showPasswordFields?: boolean
  showPasswordChangeOption?: boolean
  subtitleText: string
  userId?: string
}

const UserEdit: React.FC<UserEditProps> = ({
  disabledFields,
  email,
  handleClickDelete = () => {},
  handleClickSave,
  headingText,
  isPasswordValid = validatePassword,
  nameFirst,
  nameLast,
  role,
  showDeleteButton = false,
  showPasswordFields = false,
  showPasswordChangeOption = false,
  subtitleText,
  userId,
}) => {
  const [nameFirstDraft, setNameFirstDraft] = useState(nameFirst)
  const [nameLastDraft, setNameLastDraft] = useState(nameLast)
  const [emailDraft, setEmailDraft] = useState(email)
  const [roleDraft, setRoleDraft] = useState(role)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [showPasswordChange, setShowPasswordChange] = useState(false)
  const [isSaveDisabled, setIsSaveDisabled] = useState(
    showPasswordFields ? true : false
  )

  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [passwordValidations, setPasswordValidations] =
    useState<PasswordValidations>({})
  const { passwordChanged, passwordLength, passwordConfirmed } =
    passwordValidations
  const [hiddenRoles, setHiddenRoles] = useState<Role[]>([])

  const newPasswordError = password
    ? passwordChanged?.message ?? passwordLength?.message
    : undefined

  const newPasswordConfirmError =
    !!passwordConfirm && passwordConfirmed?.message

  const authenticatedUser = useAuthenticatedUser()
  const { activeOrganization } = useActiveOrganizationContext()

  useEffect(() => {
    if (
      authenticatedUser.role === 'emc-admin' &&
      authenticatedUser.organizationId !== activeOrganization?.id
    ) {
      setHiddenRoles(['emc-admin'])
    } else if (authenticatedUser.role === 'emc-admin') {
      setHiddenRoles([])
    } else if (authenticatedUser.role === 'admin') {
      setHiddenRoles(['emc-admin'])
    } else {
      setHiddenRoles(['emc-admin', 'admin'])
    }
  }, [authenticatedUser.organizationId, activeOrganization?.id])

  useEffect(() => {
    let isValidPassword
    if (showPasswordFields) {
      // UserInviteAccept path; password is handled in THIS form
      // There is no current password set, but our validatePassword expects one so we'll hardcode a temporary one here; because it would not pass validation requirements, there is no risk it will conflict with the new password
      const current = 'none'
      const { passwordChanged, passwordConfirmed, passwordLength } =
        isPasswordValid({
          passwordCurrent: current,
          passwordNew: password,
          passwordNewConfirm: passwordConfirm,
        })
      setPasswordValidations({
        passwordChanged,
        passwordConfirmed,
        passwordLength,
      })

      isValidPassword =
        !passwordChanged && !passwordConfirmed && !passwordLength
      setIsSaveDisabled(!isValidPassword)
    } else {
      // UserEdit path; password is handled in PasswordEdit form
      isValidPassword = true
    }

    const isSaveEnabled =
      validateEmail(emailDraft) &&
      nameFirstDraft?.trim().length &&
      nameLastDraft?.trim().length &&
      isValidPassword
    setIsSaveDisabled(!isSaveEnabled)
  }, [emailDraft, nameFirstDraft, nameLastDraft, password, passwordConfirm])

  const handleChangeNameFirst = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNameFirstDraft(event.target.value)
  }

  const handleChangeNameLast = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameLastDraft(event.target.value)
  }

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailDraft(event.target.value)
  }

  const handleChangeRole = (event: SelectChangeEvent<Role>) => {
    if (isRoleType(event.target.value)) {
      setRoleDraft(event.target.value)
    }
  }

  const handleClickSubmitPassword = () => {
    setShowPasswordChange(false)
  }

  const onSave = () => {
    handleClickSave({
      nameFirst: nameFirstDraft,
      nameLast: nameLastDraft,
      email: emailDraft,
      role: roleDraft,
    })
  }

  return (
    <Stack alignItems="center" spacing={2} maxWidth={375} width="100%">
      <ContentHeader title={headingText} body={subtitleText} />

      <TextField
        autoFocus
        fullWidth
        disabled={disabledFields?.nameFirst}
        id="name-first"
        label="First Name"
        margin="dense"
        value={nameFirstDraft}
        onChange={handleChangeNameFirst}
        variant="standard"
      />

      <TextField
        fullWidth
        disabled={disabledFields?.nameLast}
        id="name-last"
        label="Last Name"
        margin="dense"
        value={nameLastDraft}
        onChange={handleChangeNameLast}
        variant="standard"
      />

      <TextField
        fullWidth
        disabled={disabledFields?.email}
        id="email"
        label="Email"
        margin="dense"
        value={emailDraft}
        onChange={handleChangeEmail}
        variant="standard"
      />

      {disabledFields?.role && (
        <TextField
          disabled={true}
          fullWidth
          id="role"
          label="Role"
          margin="dense"
          value={roleDraft}
          variant="standard"
        />
      )}

      {!disabledFields?.role && (
        <Select
          fieldLabel="Role"
          onChange={handleChangeRole}
          id="select-role"
          name="Role"
          value={roleDraft}
        >
          {!hiddenRoles.includes('emc-admin') && (
            <MenuItem key="emc-admin" value="emc-admin">
              EMC Admin
            </MenuItem>
          )}
          {!hiddenRoles.includes('admin') && (
            <MenuItem key="admin" value="admin">
              Admin
            </MenuItem>
          )}
          <MenuItem key="viewer" value="viewer">
            Viewer
          </MenuItem>
        </Select>
      )}

      {showPasswordFields && (
        <>
          <PasswordField
            fullWidth
            name="password"
            label="Password"
            error={!!newPasswordError}
            helperText={newPasswordError}
            onChange={(
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setPassword(event.currentTarget.value)
            }}
          />
          <PasswordField
            fullWidth
            name="password"
            label="Repeat Password"
            error={!!newPasswordConfirmError}
            helperText={newPasswordConfirmError}
            onChange={(
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setPasswordConfirm(event.currentTarget.value)
            }}
          />
        </>
      )}

      {showPasswordChangeOption && userId && (
        <TextField
          fullWidth
          name="password"
          label="Password"
          disabled
          value="********"
          type="password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="update password"
                  data-testid="update password"
                  onClick={() => setShowPasswordChange(true)}
                  edge="end"
                  size="medium"
                  style={{ marginRight: 1 }}
                >
                  <ModeEdit sx={{ width: 20, height: 20 }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setPasswordConfirm(event.currentTarget.value)
          }}
        />
      )}

      <Stack spacing={2} width="100%">
        <Button disabled={isSaveDisabled} onClick={onSave} size="large">
          Submit
        </Button>
        {showDeleteButton && (
          <Button
            onClick={() => setShowDeleteConfirm(true)}
            variant="text"
            color="error"
          >
            Delete User
          </Button>
        )}
      </Stack>

      <Dialog
        isOpen={showDeleteConfirm}
        title="Delete User?"
        subtitle={
          <>
            Are you sure you want to delete this user?
            <br />
            This action cannot be undone.
          </>
        }
        handleClickCancel={() => setShowDeleteConfirm(false)}
      >
        <Button color="error" fullWidth onClick={handleClickDelete}>
          Confirm Delete
        </Button>
      </Dialog>

      {showPasswordChangeOption && userId && (
        <Dialog
          isOpen={showPasswordChange}
          subtitle={
            <>
              Update password for <strong>{email}</strong>
            </>
          }
          title="Update Password"
          handleClickCancel={() => setShowPasswordChange(false)}
        >
          <PasswordEdit
            userId={userId}
            handleClickSave={handleClickSubmitPassword}
          />
        </Dialog>
      )}
    </Stack>
  )
}

export default UserEdit
