import React from 'react'
import styled from 'styled-components'
import { Grid, Stack } from '@mui/material'
import ComplianceReportRow from './ComplianceReportRow'
import TestingCycleSectionHeader from './TestingCycleSectionHeader'
import type { TestCycleResults } from 'api/tests'
import { theme } from 'flanders-common-ui'

const StyledTable = styled.table`
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  th {
    background-color: ${theme.palette.grey[700]};
    color: white;
  }

  .location-even {
    background-color: ${theme.palette.grey.A100};
  }
`

type TestingCycleSectionProps = {
  endingAt: string | Date | null
  testCycleResults: TestCycleResults
}

const TestingCycleSection: React.FC<TestingCycleSectionProps> = ({
  endingAt,
  testCycleResults,
}) => {
  let isOdd = true
  let prevLocation = ''
  const requiresAttentionCount = testCycleResults.testCycleLocations.reduce(
    (count, location) =>
      location.testResults[0].status !== 'passed' ? count + 1 : count,
    0
  )

  return (
    <Stack key={testCycleResults.id} marginBottom={theme.units.medium}>
      <Grid
        container
        item
        justifyContent={'space-between'}
        marginBottom={theme.units.small}
      >
        <Grid
          container
          item
          justifyContent={'space-between'}
          marginBottom={theme.units.small}
        >
          <TestingCycleSectionHeader
            beginningAt={testCycleResults.beginningAt}
            endingAt={endingAt}
            locationsTestedCount={testCycleResults.testCycleLocations.length}
            requiresAttentionCount={requiresAttentionCount}
          />
        </Grid>

        <StyledTable>
          <thead>
            <tr>
              <th>Location</th>
              <th>Date/Time</th>
              <th>Type</th>
              <th>Duration</th>
              <th>Result</th>
              <th
                style={{
                  textAlign: 'right',
                }}
              >
                Requires Attention
              </th>
            </tr>
          </thead>

          <tbody>
            {testCycleResults.testCycleLocations.map((locationData) => {
              if (locationData.name !== prevLocation) {
                isOdd = !isOdd
                prevLocation = locationData.name
              }

              return locationData.testResults.map((locationHistory, index) => {
                const isMostRecentTestPerLocation = index === 0
                const requiresAttention =
                  isMostRecentTestPerLocation &&
                  locationHistory.status !== 'passed' &&
                  locationHistory.status !== 'pending'
                const resultData = {
                  isMostRecentTestPerLocation: isMostRecentTestPerLocation,
                  locationOdd: isOdd,
                  locationName: isMostRecentTestPerLocation
                    ? locationData.name
                    : ` ▸ ${locationData.name}`,
                  requiresAttention: requiresAttention,
                  startedAt: locationHistory.startedAt,
                  scheduledType: locationHistory.scheduledType,
                  status: locationHistory.status,
                  testType: locationHistory.testType,
                }

                return (
                  <ComplianceReportRow
                    key={locationHistory.id}
                    {...resultData}
                  />
                )
              })
            })}
          </tbody>
        </StyledTable>
      </Grid>
    </Stack>
  )
}

export default TestingCycleSection
