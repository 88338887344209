export type validatePasswordParams = {
  passwordCurrent: string
  passwordNew: string
  passwordNewConfirm: string
}

type ValidationError = {
  message: string
}

export type PasswordValidations = {
  passwordCurrentEmpty?: ValidationError
  passwordChanged?: ValidationError
  passwordLength?: ValidationError
  passwordConfirmed?: ValidationError
}

export const validatePassword = ({
  passwordCurrent,
  passwordNew,
  passwordNewConfirm,
}: validatePasswordParams): PasswordValidations => {
  const passwordChanged =
    passwordCurrent.length > 0 && passwordCurrent === passwordNew
  const passwordLength = passwordNew.length > 12
  const passwordConfirmed = passwordNew === passwordNewConfirm

  return {
    passwordCurrentEmpty: passwordCurrent.length
      ? undefined
      : { message: 'Current Password is required' },
    passwordChanged: !passwordChanged
      ? undefined
      : { message: 'Password did not change' },
    passwordLength: passwordLength
      ? undefined
      : { message: 'Password must be at least 12 characters' },
    passwordConfirmed: passwordConfirmed
      ? undefined
      : { message: 'Passwords do not match' },
  }
}
