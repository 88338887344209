import { apiClient } from 'api/http-common'
import type { APIFunction, GenericErrorResponse } from 'api/http-common'
import { Site, SiteResponse } from './sites-types'
import { mapSiteResposeToSite } from './mapSiteResposeToSite'

type OrganizationSitePutParams = Omit<
  Partial<Site>,
  | 'locationCount'
  | 'lastHeartbeatAt'
  | 'requiresAttentionCount'
  | 'status'
  | 'updatedAt'
> & {
  organizationId: string
  id: string
}

export const putOrganizationSite: APIFunction<
  OrganizationSitePutParams,
  Site
> = async (params) => {
  try {
    const { organizationId, id: siteId, ...siteData } = params

    const response = await apiClient.put<SiteResponse>(
      `organizations/${organizationId}/sites/${siteId}`,
      {
        address_1: siteData.address,
        address_2: siteData.addressSecond,
        city: siteData.city,
        gateway_id: siteData.gatewayId,
        emc_internal_reference_number: siteData.emcInternalReferenceNumber,
        name: siteData.name,
        state: siteData.state,
        postal_code: siteData.postalCode,
      }
    )

    return mapSiteResposeToSite(response.data)
  } catch (err: unknown) {
    const axiosError = err as GenericErrorResponse
    const { data } = axiosError.response ?? {}

    throw data?.message ?? []
  }
}
