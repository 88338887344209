import React, { PropsWithChildren } from 'react'
import { useAuth } from 'hooks/useAuth'
import { Navigate, useLocation } from 'react-router-dom'
import { login } from './routes'

const AuthenticatedRoute: React.FC<PropsWithChildren> = ({
  children = null,
}) => {
  const { user } = useAuth()
  const location = useLocation()

  if (!user) {
    return (
      <Navigate
        to={login}
        replace
        state={{
          from: location,
        }}
      />
    )
  }

  return <>{children}</>
}

export default AuthenticatedRoute
