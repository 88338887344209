import { useQuery } from 'react-query'
import { fetchOrganizationOverviewById } from './fetchOrganizationOverviewById'
import { OrganizationOverview } from './organization-types'

export const useFetchOrganizationOverviewById = (organizationId: string) => {
  return useQuery<OrganizationOverview, string[]>(
    ['fetchOrganizationOverviewById', organizationId],
    fetchOrganizationOverviewById(organizationId)
  )
}
