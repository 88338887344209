import React from 'react'
import { TextField } from 'flanders-common-ui'
import macAddressImgSrc from './mac-address-2.png'

export const GatewayIdInput: React.FC<{
  value: string
  onChange: (value: string) => void
}> = ({ value, onChange }) => (
  <>
    <img src={macAddressImgSrc} alt="Mac Address Image" />
    <TextField
      autoFocus
      fullWidth
      id="site-gateway-id"
      label="Local Gateway ID"
      margin="dense"
      value={value}
      onChange={(event) => onChange(event.target.value)}
      variant="standard"
    />
  </>
)
