import React from 'react'
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers'
import moment from 'moment'
import { lighten } from '@mui/material/styles'
import { theme } from 'flanders-common-ui'

const PickersDayScheduledTest: React.FC<PickersDayProps<moment.Moment>> = ({
  ...pickersDayProps
}) => (
  <PickersDay
    {...pickersDayProps}
    sx={{
      backgroundColor: theme.palette.warning.light,
      border: `2px solid ${theme.palette.warning.main}`,
      ':hover': {
        backgroundColor: lighten(theme.palette.warning.main, 0.7),
      },
    }}
  />
)

export default PickersDayScheduledTest
