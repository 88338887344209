import { useQuery } from 'react-query'
import {
  fetchSitesListPaginated,
  SitesListRequestParams,
} from './fetchSitesListPaginated'

export const useFetchSitesListPaginated = (
  pageRequestParams: SitesListRequestParams
) => {
  return useQuery(
    ['fetchSitesListPaginated', pageRequestParams],
    fetchSitesListPaginated(pageRequestParams)
  )
}
