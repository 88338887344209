import React from 'react'
import { Paper, Stack } from '@mui/material'
import UserEdit from 'components/organisms/forms/UserEdit'
import { Role } from 'api/users'
import { useNavigate } from 'react-router-dom'
import { dashboard } from 'routes/routes'

const user = {
  id: 'id',
  email: 'mockuser@dangerzone.com',
  nameFirst: 'nameFirst',
  nameLast: 'nameLast',
  role: 'viewer' as Role,
}
const organizationName = 'organizationName'

const UserInviteAccept: React.FC = () => {
  const navigate = useNavigate()

  return (
    <Stack
      data-test-id="users-create-account-page-container"
      spacing={1}
      p={1}
      flexGrow={1}
      justifyContent="center"
    >
      <Paper
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          overflow: 'hidden',
          px: 2,
          py: 2,
        }}
      >
        <UserEdit
          email={user.email}
          handleClickSave={() => {
            navigate(dashboard)
          }}
          headingText={'Create Your Account'}
          subtitleText={`You've been invited to join the ${organizationName} ELTS Organization`}
          disabledFields={{
            role: true,
            email: true,
          }}
          nameFirst={user.nameFirst}
          nameLast={user.nameLast}
          role={user.role}
          showPasswordFields={true}
        />
      </Paper>
    </Stack>
  )
}

export default UserInviteAccept
