import React from 'react'
import { Stack } from '@mui/material'
import { Button, ContentHeader } from 'flanders-common-ui'

export type SuccessLayoutProps = {
  handleClickPrimary: () => void
  handleClickSecondary?: () => void | undefined
  heading: string
  icon: React.ReactNode
  primaryLabel: string
  secondaryLabel?: string
  subtitle: string
}
const SuccessLayout: React.FC<SuccessLayoutProps> = ({
  handleClickPrimary,
  handleClickSecondary = () => {},
  heading,
  icon,
  primaryLabel,
  secondaryLabel = '',
  subtitle,
}) => {
  return (
    <Stack alignItems="center" spacing={2} maxWidth={375} width="100%">
      <ContentHeader title={heading} body={subtitle} />

      <Stack width="100%" alignItems="center" paddingBottom={2} spacing={2}>
        {icon}
        <Button size="large" fullWidth onClick={handleClickPrimary}>
          {primaryLabel}
        </Button>
        {secondaryLabel.length >= 1 && (
          <Button onClick={handleClickSecondary} variant="text">
            {secondaryLabel}
          </Button>
        )}
      </Stack>
    </Stack>
  )
}

export default SuccessLayout
