import {
  apiClient,
  APICurriedFunction,
  GenericErrorResponse,
} from 'api/http-common'
import { Page, PageRequestParams } from 'api/page'
import { mapUpcomingTestResponseToUpcomingTest } from './mapUpcomingTestResponseToUpcomingTest'
import type { UpcomingTestResponse, UpcomingTest } from './test-types'

export type UpcomingTestsRequest = PageRequestParams & {
  locationId: string
  organizationId: string
  siteId: string
}

export const fetchUpcomingTestsByLocationId: APICurriedFunction<
  UpcomingTestsRequest,
  Page<UpcomingTest>
> = (params) => async () => {
  try {
    const { locationId, page, page_size } = params
    const response = await apiClient.get<Page<UpcomingTestResponse>>(
      `/organizations/${params.organizationId}/sites/${params.siteId}/tests/upcoming`,
      {
        params: {
          location_id: locationId,
          page,
          page_size,
        },
      }
    )

    return {
      ...response.data,
      entries: response.data.entries.map(mapUpcomingTestResponseToUpcomingTest),
    }
  } catch (err: unknown) {
    const axiosError = err as GenericErrorResponse
    const { data } = axiosError.response ?? {}

    throw data?.message ?? []
  }
}
