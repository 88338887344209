import React, { useState } from 'react'
import { AboveFoldLayout, useToastAlertProvider } from 'flanders-common-ui'
import { Paper, Stack } from '@mui/material'
import OrganizationSiteDetailsForm from './OrganizationSiteDetailsForm'
import OrganizationSiteGatewayIdForm from './OrganizationSiteGatewayIdForm'
import { useNavigate } from 'react-router-dom'
import { organizations } from 'routes/routes'
import { Site } from 'api/sites'
import { postOrganizationSite } from 'api/sites/postOrganizationSite'
import { useActiveOrganizationContext } from 'hooks/useActiveOrganization'

type UIStates = 'initial' | 'enter_gateway_id' | 'submitting'

const OrganizationSitesAddSitePage: React.FC = () => {
  const navigate = useNavigate()
  const { activeOrganization } = useActiveOrganizationContext()
  const { setToast } = useToastAlertProvider()
  const [uiState, setUiState] = useState<UIStates>('initial')
  const [siteDetailsDraft, setSiteDetailsDraft] = useState<
    Partial<Site> | undefined
  >(undefined)
  const organizationName = activeOrganization?.name ?? ''

  const handleClickNext = (updatedSiteData: Partial<Site>) => {
    setSiteDetailsDraft((prevData) => ({ ...prevData, ...updatedSiteData }))
    setUiState('enter_gateway_id')
  }

  const handleClickBack = (gatewayId: string) => {
    setSiteDetailsDraft((prevData) => ({ ...prevData, gatewayId }))
    setUiState('initial')
  }

  const handleClickSubmit = async (gatewayId: string) => {
    setSiteDetailsDraft((prevData) => ({ ...prevData, gatewayId }))

    if (
      gatewayId &&
      gatewayId.length &&
      activeOrganization &&
      activeOrganization.id.length &&
      siteDetailsDraft &&
      siteDetailsDraft.address &&
      siteDetailsDraft.address.length &&
      siteDetailsDraft.city &&
      siteDetailsDraft.city.length &&
      siteDetailsDraft.emcInternalReferenceNumber &&
      siteDetailsDraft.emcInternalReferenceNumber.length &&
      siteDetailsDraft.name &&
      siteDetailsDraft.name.length &&
      siteDetailsDraft.state &&
      siteDetailsDraft.state.length &&
      siteDetailsDraft.postalCode &&
      siteDetailsDraft.postalCode.length
    ) {
      try {
        const response = await postOrganizationSite({
          address1: siteDetailsDraft.address,
          address2: siteDetailsDraft?.addressSecond ?? '',
          city: siteDetailsDraft.city,
          emcInternalReferenceNumber:
            siteDetailsDraft.emcInternalReferenceNumber,
          gatewayId: gatewayId,
          name: siteDetailsDraft.name,
          organizationId: activeOrganization.id,
          state: siteDetailsDraft.state,
          zipcode: siteDetailsDraft.postalCode,
        })

        if (response.id) {
          setToast({ message: 'Site added succesfully', severity: 'success' })
          navigate(`${organizations}/${activeOrganization.id}/sites`)
        }
      } catch {
        setToast({ message: 'Site add failed', severity: 'error' })
      }
    } else {
      // The forms validate, so this scenario _should_ be impossible to hit; but just in case, we'll show an error
      setToast({ message: 'Invalid Site Data', severity: 'error' })
    }
  }

  return (
    <>
      <AboveFoldLayout breadcrumbRoutes={[]} heading="Add New Site" />
      <Stack alignItems="center" component={Paper} pb={'48px'}>
        {uiState === 'initial' && (
          <OrganizationSiteDetailsForm
            variant="create"
            siteData={siteDetailsDraft}
            organizationName={organizationName}
            onClickSubmit={handleClickNext}
          />
        )}

        {uiState === 'enter_gateway_id' && (
          <OrganizationSiteGatewayIdForm
            gatewayId={siteDetailsDraft?.gatewayId}
            onClickBack={handleClickBack}
            onClickSubmit={handleClickSubmit}
          />
        )}
      </Stack>
    </>
  )
}

export default OrganizationSitesAddSitePage
