import { mapTestResultsResponseToTestResults } from './mapTestResultsResponseToTestResults'
import {
  TestCycleResultsResponseLocation,
  TestCycleResultsLocation,
} from './test-types'

export const mapTestCycleResultsLocationResponseToTestCycleResultsLocation = (
  location: TestCycleResultsResponseLocation
): TestCycleResultsLocation => ({
  id: location.id,
  name: location.name,
  testResults: location.test_results.map(mapTestResultsResponseToTestResults),
})
