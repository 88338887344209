import { GenericErrorResponse } from 'api/http-common'
import type { Location, LocationDetailsRequestParams } from 'api/locations'
import { fetchLocationById } from 'api/locations'
import { useQuery } from 'react-query'

export const useFetchLocationById = (params: LocationDetailsRequestParams) => {
  return useQuery<Location, GenericErrorResponse>(
    ['fetchLocationById', params],
    fetchLocationById(params)
  )
}
