import type { Location } from 'api/locations'
import {
  StatusChipProps,
  StatusIcon,
  TestIcon,
  theme,
} from 'flanders-common-ui'

const STATUS: Record<
  string,
  Pick<StatusChipProps, 'label' | 'status' | 'icon'>
> = {
  unverified: { label: 'Unverified', status: 'warning' },
  offline: { label: 'Offline', status: 'error' },
  online: { label: 'Online', status: 'ok' },
  deranged: {
    label: 'Misconfigured Sensors',
    status: 'error',
    icon: <StatusIcon status="Misconfigured Sensors" width={18} height={18} />,
  },
  running: {
    label: 'Test Running',
    status: 'info',
    icon: (
      <TestIcon iconColor={theme.palette.info.main} width={18} height={18} />
    ),
  },
}

export const getLocationNodeStatusChipProps = ({
  node,
  visuallyVerifiedAt,
  lastTest,
}: Location): Pick<StatusChipProps, 'label' | 'status'> => {
  if (visuallyVerifiedAt === null) {
    return STATUS.unverified
  } else if (!node || node.priority_status === 'offline') {
    return STATUS.offline
  } else if (lastTest?.status === 'running') {
    return STATUS.running
  } else if (node.priority_status === 'sensor_error') {
    return STATUS.deranged
  } else if (node.priority_status === 'online') {
    return STATUS.online
  } else {
    console.error(
      `Unmatched status: ${node.priority_status}; failing safe to OFFLINE`
    )
    return STATUS.offline //fail safe!
  }
}
