import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { Location, useLocation, useNavigate } from 'react-router-dom'
import { useLocalStorage } from './useLocalStorage'
import {
  deleteAccessToken,
  fetchJWTLogin,
  User,
  UserCredentials,
  UserTokens,
} from 'api/users'
import { dashboard, organizations, login as loginRoute } from 'routes/routes'

export type UserAuthContext = {
  login: (data: UserCredentials) => Promise<void>
  logout: () => void
  updateUser: (updatedNameFirst: string, updatedNameLast: string) => void
  user?: UserTokens
}

const initialContext = {
  login: () => Promise.resolve(),
  logout: () => {},
  updateUser: () => {},
}

const AuthContext = createContext<UserAuthContext>(initialContext)

type AuthProviderProps = {
  initialUser?: UserTokens
}

function isFromState(state: unknown): state is { from?: Location } {
  return state !== null && state !== undefined && Object.hasOwn(state, 'from')
}

export const AuthProvider: React.FC<PropsWithChildren<AuthProviderProps>> = ({
  children,
  initialUser,
}) => {
  const [user, setUser] = useLocalStorage<UserTokens>('user', initialUser)
  const navigate = useNavigate()
  const location = useLocation()
  const from = isFromState(location.state) ? location.state.from : undefined

  // call this function when you want to authenticate the user
  const login = useCallback(
    async (credentials: UserCredentials) => {
      const tokens = await fetchJWTLogin(credentials)
      setUser(tokens)

      const postLoginRoute =
        tokens.user.role === 'emc-admin' ? organizations : dashboard
      navigate(from?.pathname ?? postLoginRoute, { replace: true })
    },
    [navigate, setUser, from?.pathname]
  )

  const logout = useCallback(async () => {
    try {
      await deleteAccessToken() // logout user server-side
    } catch (error: unknown) {
      console.error(error)
    } finally {
      setUser(undefined) // logout user client-side
      navigate(loginRoute, { replace: true })
    }
  }, [navigate, setUser])

  const updateUser = (updatedNameFirst: string, updatedNameLast: string) => {
    if (user) {
      setUser({
        ...user,
        user: {
          ...user.user,
          nameFirst: updatedNameFirst,
          nameLast: updatedNameLast,
        },
      })
    }
  }

  useEffect(() => {
    /**
     * Validate local storage which can change without triggering the above handleCacheClear hook
     * Cache will be forced clear when server responds with a 401 unauthenticated user response via http-common
     *
     * This is intentionally run on every page load and network request,
     * does not re-run when from client-side-ONLY interaction
     *
     */
    if (
      typeof user !== 'undefined' &&
      window.localStorage.user === 'undefined'
    ) {
      logout()
    }
  })

  const value = useMemo(
    () => ({
      login,
      logout,
      updateUser,
      user,
    }),
    [login, logout, updateUser, user]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}

export const useAuthenticatedUser = (): User => {
  const { user: userTokens } = useAuth()

  if (!userTokens) throw new Error('No authenticated user')

  return userTokens.user
}
