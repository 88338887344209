import { useQuery } from 'react-query'
import {
  fetchOrganizationsListPaginated,
  OrganizationsListRequestParams,
} from './fetchOrganizationsListPaginated'

export const useFetchOrganizationsListPaginated = (
  params: OrganizationsListRequestParams
) => {
  return useQuery(
    ['fetchOrganizationsListPaginated', params],
    fetchOrganizationsListPaginated(params)
  )
}
