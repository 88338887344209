import { apiClient } from 'api/http-common'
import type { GenericErrorResponse, APIFunction } from 'api/http-common'
import { UserCredentials, UserTokens, UserTokensResponse } from './users-types'
import { mapUserTokensResponseToUserTokens } from './mapUserTokensResponseToUserTokens'

export const fetchJWTLogin: APIFunction<UserCredentials, UserTokens> = async ({
  email,
  password,
}) => {
  try {
    const response = await apiClient.post<UserTokensResponse>('/log_in', {
      credentials: {
        email,
        password,
      },
    })

    return mapUserTokensResponseToUserTokens(response.data)
  } catch (err: unknown) {
    const axiosError = err as GenericErrorResponse
    const { data } = axiosError.response ?? {}

    throw data?.message ?? ['Error logging in.']
  }
}
