import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import {
  Box,
  Container,
  Divider,
  IconButton,
  Toolbar,
  useMediaQuery,
  CssBaseline,
  Drawer,
  AppBar,
} from '@mui/material'
import { Menu as MenuIcon } from '@mui/icons-material'
import { Footer, logoImage, theme } from 'flanders-common-ui'
import PrivacyPolicy from '../legal/PrivacyPolicy'
import TermsAndConditions from '../legal/TermsAndConditions'
import Nav from './Nav'
import {
  EMCAdminRouteLabels,
  RouteLabels,
  ViewerRouteLabels,
} from 'routes/route-labels'
import ActiveUserMenu from './ActiveUserMenu'
import { dashboard, organizations } from 'routes/routes'
import { useAuth } from 'hooks/useAuth'
import { useActiveOrganizationContext } from 'hooks/useActiveOrganization'

type SidebarNavigationLayoutProps = {
  id?: string
  variant?: 'admin' | 'emcAdmin'
}

const drawerWidth = 240

const navRoutesFiltered = RouteLabels.filter(
  (routeLabelData) => routeLabelData.Icon
)
const viewerNavRoutesFiltered = ViewerRouteLabels.filter(
  (routeLabelData) => routeLabelData.Icon
)
const emcAdminNavRoutesFiltered = EMCAdminRouteLabels.filter(
  (routeLabelData) => routeLabelData.Icon
)

const SidebarNavigationLayout: React.FC<SidebarNavigationLayoutProps> = ({
  variant = 'admin',
  ...sidebarNavigationLayout
}) => {
  const [open, setOpen] = React.useState(false)

  const { user } = useAuth()
  const location = useLocation()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const navigate = useNavigate()

  const activeUsername = user?.user.email //TODO: Replace with names when API returns firstname/lastname
  const activeUserId = user?.user.id
  const activeUserRole = user?.user.role
  const showEMCAdminMenu =
    activeUserRole === 'emc-admin' &&
    !location.pathname.includes('organizations') &&
    !location.pathname.includes('emc-admin')
  const { activeOrganization } = useActiveOrganizationContext()

  useEffect(() => {
    const updateURL = () => {
      const joinChar = location.search.includes('?') ? '&' : '?'
      navigate(
        `${location.pathname}${location.search}${joinChar}organization_name=${activeOrganization?.name}`,
        {
          replace: true,
        }
      )
    }

    const isEmcAdminViewingOrg =
      showEMCAdminMenu && !location.search.includes('organization_name=')
    const isEmcAdminMessingWithUrl =
      showEMCAdminMenu &&
      location.search.length === '&organization_name='.length

    if (isEmcAdminViewingOrg || isEmcAdminMessingWithUrl) {
      updateURL()
    }
  }, [location.pathname, location.search])

  const toggleDrawer = () => {
    setOpen((open) => !open)
  }

  const closeDrawer = () => {
    setOpen(false)
  }

  return (
    <Box sx={{ display: 'flex' }} {...sidebarNavigationLayout}>
      <CssBaseline />
      {/* App Bar */}
      <AppBar color="inherit" position="fixed">
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="toggle drawer"
            onClick={toggleDrawer}
            sx={{
              display: isMdUp ? 'none' : 'flex',
              marginTop: '0 !important',
            }}
          >
            <MenuIcon />
          </IconButton>
          <img
            width={isMdUp ? 102 : 96}
            src={logoImage}
            alt="ELTS Logo"
            onClick={() => {
              navigate(
                activeUserRole === 'emc-admin' ? organizations : dashboard
              )
            }}
            style={{
              marginTop: '0 !important',
            }}
          />
          {isMdUp && activeUserId && activeUsername ? (
            <ActiveUserMenu
              userId={activeUserId}
              username={activeUsername}
              onCloseDrawer={closeDrawer}
            />
          ) : (
            <div style={{ width: '31px', height: '31px' }} />
          )}
        </Toolbar>
      </AppBar>

      {/* Drawer */}
      <Drawer
        variant={isMdUp ? 'permanent' : 'temporary'}
        open={isMdUp ? true : open}
        anchor="left"
        onClose={toggleDrawer}
        sx={{
          zIndex: '1000',
          display: { sm: 'block', width: open || isMdUp ? 240 : 0 },
          '& .MuiDrawer-paper': { boxSizing: 'border-box' },
        }}
        PaperProps={{
          sx: {
            width: drawerWidth,
          },
        }}
      >
        <Toolbar sx={{ display: 'flex' }} />

        <Divider />
        {activeUserId &&
          activeUserRole &&
          activeUsername &&
          activeOrganization && (
            <Nav
              navRouteData={
                variant === 'emcAdmin'
                  ? emcAdminNavRoutesFiltered
                  : activeUserRole === 'viewer'
                  ? viewerNavRoutesFiltered
                  : navRoutesFiltered
              }
              userId={activeUserId}
              username={activeUsername}
              showEMCAdminMenu={showEMCAdminMenu}
              showUserMenu={!isMdUp}
              onCloseDrawer={closeDrawer}
              organizationName={activeOrganization.name}
            />
          )}
      </Drawer>

      {/* Main */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Divider />
        <Container
          maxWidth="xl"
          data-test-id="main-content-section"
          sx={{
            mt: 4,
            minHeight: '85%',
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
          }}
        >
          <Outlet />
        </Container>
        <Footer
          privacyPolicy={<PrivacyPolicy />}
          termsAndConditions={<TermsAndConditions />}
        />
      </Box>
    </Box>
  )
}

export default SidebarNavigationLayout
