import { deriveSiteStatusFromResponse } from 'api/sites'
import { Organization, OrganizationResponse } from './organization-types'

export const mapOrganizationResponseToOrganization = (
  organizationResponse: OrganizationResponse
): Organization => ({
  id: organizationResponse.id,
  address1: organizationResponse.address_1,
  address2: organizationResponse.address_2,
  anniversaryDate: organizationResponse.anniversary_date,
  city: organizationResponse.city,
  name: organizationResponse.name,
  offlineDaysCount: organizationResponse.offline_days_count,
  postalCode: organizationResponse.postal_code,
  requireAttentionCount: organizationResponse.require_attention_count,
  siteCount: organizationResponse.site_count,
  state: organizationResponse.state,
  status: deriveSiteStatusFromResponse({
    requires_attention_count: organizationResponse.require_attention_count,
    offline_days_count: organizationResponse.offline_days_count,
  }),
})
