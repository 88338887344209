import React from 'react'
import { GridColDef } from '@mui/x-data-grid'
import { NavLink, useNavigate } from 'react-router-dom'
import DataGrid from 'components/molecules/DataGrid'
import { Paper, Stack } from '@mui/material'
import { useFetchUsersListPaginated, User } from 'api/users'
import { AboveFoldLayout, Button } from 'flanders-common-ui'
import { emcAdminUsersAdd } from 'routes/routes'
import { RouteLabels } from 'routes/route-labels'
import { useActiveOrganizationContext } from 'hooks/useActiveOrganization'

function useColumns(): GridColDef<User>[] {
  const columns: GridColDef<User>[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
      renderCell: ({ row: user }) => {
        return (
          <NavLink key={user.id} to={`/users/${user.id}`}>
            {user.nameFirst} {user.nameLast}
          </NavLink>
        )
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
  ]

  return columns
}

const EMCUsersPage: React.FC = () => {
  const columns = useColumns()
  const navigate = useNavigate()
  const { activeOrganization } = useActiveOrganizationContext()
  const routelabelData = RouteLabels.find((obj) => {
    return obj.key === 'Users'
  })

  return (
    <>
      {routelabelData && (
        <AboveFoldLayout
          breadcrumbRoutes={[routelabelData]}
          heading="EMC Users"
        >
          <Button
            size="small"
            onClick={() => {
              navigate(emcAdminUsersAdd)
            }}
          >
            + New User
          </Button>
        </AboveFoldLayout>
      )}
      <Stack component={Paper} spacing={1} px={2} pt="20px">
        <Stack width="100%">
          <DataGrid<User, { organization_id: string }>
            columns={columns}
            dataId="users-table"
            requestExtraParams={{
              organization_id: activeOrganization?.id ?? '',
            }}
            useFetchPaginatedData={useFetchUsersListPaginated}
          />
        </Stack>
      </Stack>
    </>
  )
}

export default EMCUsersPage
