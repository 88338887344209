import {
  apiClient,
  APICurriedFunction,
  GenericErrorResponse,
} from 'api/http-common'
import type { SiteOverviewResponse } from 'api/locations'

export type SiteOverviewByIdRequestParams = {
  organizationId: string
  siteId: string
}

export const fetchSiteOverviewById: APICurriedFunction<
  SiteOverviewByIdRequestParams,
  SiteOverviewResponse
> = (params) => async () => {
  try {
    const response = await apiClient.get<SiteOverviewResponse>(
      `organizations/${params.organizationId}/sites/${params.siteId}/overview`
    )

    return response.data
  } catch (error) {
    const axiosError = error as GenericErrorResponse
    const { data } = axiosError.response ?? {}
    const defaultErrorMessage = 'Error fetching overview data'

    console.error(defaultErrorMessage, error)

    throw data?.message ?? [defaultErrorMessage]
  }
}
