export function setValueOnLocalStorage<T = Record<string, unknown>>(
  key: string,
  newValue?: T,
  callback: (value?: T) => void = () => {}
) {
  try {
    window.localStorage.setItem(key, JSON.stringify(newValue))
    callback(newValue)
  } catch (err) {
    console.error(err)
  }
}
