import { useQuery } from 'react-query'
import { fetchTestHistoryByLocationId } from 'api/tests'
import type { TestHistoryByLocationIdPageRequestParams } from 'api/tests'

export const useFetchTestHistoryByLocationId = (
  params: TestHistoryByLocationIdPageRequestParams
) => {
  return useQuery(
    ['fetchTestHistoryByLocationId', params],
    fetchTestHistoryByLocationId(params)
  )
}
