import { useQuery } from 'react-query'
import { fetchLocationsListPaginated } from 'api/locations'
import type { LocationsListRequestParams } from 'api/locations'

export const useFetchLocationsListPaginated = (
  params: LocationsListRequestParams
) => {
  return useQuery(
    ['fetchLocationsListPaginated', params],
    fetchLocationsListPaginated(params)
  )
}
