import {
  apiClient,
  APICurriedFunction,
  GenericErrorResponse,
} from 'api/http-common'
import { Page, PageRequestParams } from 'api/page'
import { mapSiteResposeToSite } from './mapSiteResposeToSite'
import { Site, SiteResponse } from './sites-types'

export type SitesListRequestParams = PageRequestParams & {
  organizationId?: string
  requiringAttention?: boolean
}

export const fetchSitesListPaginated: APICurriedFunction<
  SitesListRequestParams,
  Page<Site>
> = (requestParams) => async () => {
  try {
    if (!requestParams.organizationId) {
      throw new Error('Invalid organization')
    }

    const { data: sitesPageResponse } = await apiClient.get<Page<SiteResponse>>(
      `/organizations/${requestParams.organizationId}/sites`,
      {
        params: {
          page: requestParams.page,
          page_size: requestParams.page_size,
          ...(requestParams.requiringAttention === true && {
            requiring_attention: true,
          }),
        },
      }
    )

    return {
      ...sitesPageResponse,
      entries: sitesPageResponse.entries.map(mapSiteResposeToSite),
    }
  } catch (err: unknown) {
    const axiosError = err as GenericErrorResponse
    const { data } = axiosError.response ?? {}

    throw data?.message ?? []
  }
}
