import moment from 'moment'
import { deriveSiteStatusFromResponse } from './deriveSiteStatusFromResponse'
import { Site, SiteResponse } from './sites-types'

export const mapSiteResposeToSite = (siteResponse: SiteResponse): Site => ({
  id: siteResponse.id,
  address: siteResponse.address_1,
  addressSecond: siteResponse.address_2,
  city: siteResponse.city,
  gatewayId: siteResponse.gateway_id,
  emcInternalReferenceNumber: siteResponse.emc_internal_reference_number,
  lastHeartbeatAt: siteResponse.last_heartbeat_at,
  locationCount: siteResponse.location_count,
  name: siteResponse.name,
  organizationId: siteResponse.organization_id,
  requiresAttentionCount: siteResponse.requires_attention_count,
  state: siteResponse.state,
  status: deriveSiteStatusFromResponse(siteResponse),
  updatedAt: siteResponse.updated_at
    ? moment(siteResponse.updated_at).toDate()
    : null,
  postalCode: siteResponse.postal_code,
})
