import { User, UserResponse } from './users-types'

export const mapUserResponseToUser = (userResponse: UserResponse): User => ({
  id: userResponse.id,
  email: userResponse.email,
  nameFirst: userResponse.first_name,
  nameLast: userResponse.last_name,
  organizationId: userResponse.organization_id,
  role: userResponse.role === 'emc_admin' ? 'emc-admin' : userResponse.role,
})
