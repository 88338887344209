import React from 'react'
import { NavLink } from 'react-router-dom'
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material'
import { theme, Typography } from 'flanders-common-ui'
import { EMCAdminLabel, Label, NavRoute } from 'routes/route-labels'
import ActiveUserMenu from './ActiveUserMenu'
import { emcAdminUsers, organizations } from 'routes/routes'
import { useActiveOrganizationContext } from 'hooks/useActiveOrganization'
import { useAuth } from 'hooks/useAuth'

const linkStylesCommon = {
  textDecoration: 'none',
  display: 'block',
}

const linkActiveStyles = {
  ...linkStylesCommon,
  color: theme.palette.grey[900],
  backgroundColor: theme.palette.UI.light,
}

const linkInactiveStyles = {
  ...linkStylesCommon,
  color: theme.palette.grey[600],
}

export type NavProps = {
  navRouteData: NavRoute<Label>[] | NavRoute<EMCAdminLabel>[]
  userId: string
  username: string
  showEMCAdminMenu?: boolean
  showUserMenu?: boolean
  onCloseDrawer: () => void
  organizationName?: string
}

const Nav: React.FC<NavProps> = ({
  navRouteData,
  userId,
  username,
  showEMCAdminMenu = false,
  showUserMenu = false,
  onCloseDrawer,
  organizationName = '',
}) => {
  const { user } = useAuth()
  const { updateActiveOrganization } = useActiveOrganizationContext()

  return (
    <Stack flexGrow={1}>
      {showEMCAdminMenu && (
        <Stack
          alignItems={'center'}
          padding={2}
          borderBottom={'1px solid #E7ECF3'}
        >
          <div>{organizationName}</div>
          <NavLink
            key={'Organizations'}
            to={organizations}
            replace
            style={{
              fontSize: '12px',
              fontWeight: 'bold',
              textDecoration: 'none',
            }}
            onClick={(event) => {
              if (event.ctrlKey || event.metaKey) {
                // metaKey === Mac's command pressed
                event.preventDefault()
                return
              }

              updateActiveOrganization({
                id: user?.user.organizationId ?? '',
                name: '',
              })
              onCloseDrawer()
            }}
          >
            Return to EMC
          </NavLink>
        </Stack>
      )}

      <List component="nav" style={{ padding: 0 }}>
        {navRouteData.map((data) => (
          <NavLink
            key={data.label}
            to={data.path}
            style={({ isActive }) =>
              isActive ? linkActiveStyles : linkInactiveStyles
            }
            onClick={() => {
              // Ensures EMC Users page pulls from the right org
              if (data.path === emcAdminUsers) {
                updateActiveOrganization({
                  id: user?.user.organizationId ?? '',
                  name: '',
                })
              }
              onCloseDrawer()
            }}
          >
            <ListItemButton
              style={{
                borderBottom: `solid 1px ${theme.palette.UI.main}`,
                paddingTop: theme.units.large,
                paddingBottom: theme.units.large,
              }}
            >
              <ListItemIcon
                style={{
                  color: 'inherit',
                }}
              >
                {data.Icon && <data.Icon />}
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="body" weight="semiBold">
                    {data.label}
                  </Typography>
                }
              />
            </ListItemButton>
          </NavLink>
        ))}
      </List>
      {showUserMenu && (
        <ActiveUserMenu
          variant="list"
          userId={userId}
          username={username}
          onCloseDrawer={onCloseDrawer}
        />
      )}
    </Stack>
  )
}

export default Nav
