import type { GenericErrorResponse } from 'api/http-common'
import type { Page } from 'api/page'
import { useQuery } from 'react-query'
import { fetchUpcomingTestsByLocationId } from './fetchUpcomingTestsByLocationId'
import type { UpcomingTestsRequest } from './fetchUpcomingTestsByLocationId'
import type { UpcomingTest } from './test-types'

export const useFetchUpcomingTestsByLocationId = (
  pageRequestParams: UpcomingTestsRequest
) => {
  return useQuery<Page<UpcomingTest>, GenericErrorResponse>(
    ['fetchUpcomingTestsByLocationId', pageRequestParams],
    fetchUpcomingTestsByLocationId(pageRequestParams)
  )
}
