import React, { PropsWithChildren } from 'react'
import { useAuth } from 'hooks/useAuth'
import { Navigate, useLocation } from 'react-router-dom'
import { Role } from 'api/users'

export type AuthorizedRoleRouteProps = PropsWithChildren & {
  authorizedRoles: Role[]
  toRoute: string
}

const AuthorizedRoleRoute: React.FC<AuthorizedRoleRouteProps> = ({
  authorizedRoles,
  children = null,
  toRoute,
}) => {
  const { user } = useAuth()
  const location = useLocation()
  const isAuthorized =
    user?.user.role && authorizedRoles.includes(user.user.role)

  if (isAuthorized) {
    return <>{children}</>
  } else {
    console.warn('403 Forbidden')
    return (
      <Navigate
        to={toRoute}
        replace
        state={{
          from: location,
        }}
      />
    )
  }
}

export default AuthorizedRoleRoute
