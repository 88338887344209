import React from 'react'
import { Link, NavigateFunction, useNavigate } from 'react-router-dom'
import { Paper, Stack } from '@mui/material'
import { AboveFoldLayout, Button } from 'flanders-common-ui'
import { organizationAdd, organizations } from 'routes/routes'
import { useFetchOrganizationsListPaginated } from 'api/organizations'
import DataGrid from 'components/molecules/DataGrid'
import { Organization } from 'api/organizations'
import { GridColDef } from '@mui/x-data-grid'
import Status from 'components/molecules/Status'
import { formatDateOnly } from 'utils/date-utils'
import moment from 'moment'
import { CorporateFare, ModeOutlined, PeopleOutline } from '@mui/icons-material'
import { useActiveOrganizationContext } from 'hooks/useActiveOrganization'

function useColumns({
  navigate,
  updateActiveOrganization,
}: {
  navigate: NavigateFunction
  updateActiveOrganization: (orgId: string, orgName: string) => void
}): GridColDef<Organization>[] {
  const columns: GridColDef<Organization>[] = [
    {
      field: 'name',
      headerName: 'Organization Name',
      flex: 2,
      renderCell: ({ row: organization }) => {
        return (
          <span
            onClick={() =>
              updateActiveOrganization(organization.id, organization.name)
            }
          >
            <Link to={`/dashboard`}>{organization.name}</Link>
          </span>
        )
      },
    },
    {
      field: 'anniversaryDate',
      headerName: 'Anniversary',
      flex: 1,
      sortComparator: (date1, date2) => moment(date1).diff(moment(date2)),
      renderCell: ({ row: organization }) =>
        formatDateOnly(organization.anniversaryDate),
    },
    {
      field: 'requireAttentionCount',
      headerName: 'Site Status',
      flex: 1,
      renderCell: ({ row: organization }) => {
        if (organization.status === 'requires_attention') {
          return (
            <Status
              status={`${organization.requireAttentionCount} Sites(s)`}
              description="Require Attention"
            />
          )
        } else if (organization.status === 'gateway_offline') {
          return (
            <Status
              status="Gateway Offline"
              description={`Inactive ${organization.offlineDaysCount} Day(s)`}
            />
          )
        } else {
          return <Status status="Healthy" showCheckIcon={true} />
        }
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'right',
      flex: 1,
      align: 'right',
      renderCell: ({ row: organization }) => {
        const commonIconProps = { width: 24, height: 24, cursor: 'pointer' }

        return (
          <span
            onClick={() =>
              updateActiveOrganization(organization.id, organization.name)
            }
          >
            <CorporateFare
              color="disabled"
              sx={{ ...commonIconProps, mr: 1 }}
              onClick={() => {
                navigate(`${organizations}/${organization.id}/sites`)
              }}
            />
            <PeopleOutline
              color="disabled"
              sx={{ ...commonIconProps, mr: 1 }}
              onClick={() => {
                navigate(`/users`)
              }}
            />
            <ModeOutlined
              color="disabled"
              sx={{ ...commonIconProps }}
              onClick={() => {
                navigate(`${organizations}/${organization.id}/edit`, {
                  state: { organization },
                })
              }}
            />
          </span>
        )
      },
    },
  ]

  return columns
}

const OrganizationsPage: React.FC = () => {
  const navigate = useNavigate()
  const { updateActiveOrganization } = useActiveOrganizationContext()

  const updateActiveOrg = (orgId: string, orgName: string) => {
    updateActiveOrganization({
      id: orgId,
      name: orgName,
    })
  }

  const columns = useColumns({
    navigate: navigate,
    updateActiveOrganization: updateActiveOrg,
  })

  return (
    <>
      <AboveFoldLayout breadcrumbRoutes={[]} heading="Organizations">
        <Button
          size="small"
          onClick={() => {
            navigate(organizationAdd)
          }}
        >
          + New Organization
        </Button>
      </AboveFoldLayout>
      <Stack component={Paper} spacing={1} px={2} pt="20px">
        <DataGrid<Organization>
          columns={columns}
          dataId="organizations-table"
          requestExtraParams={{}}
          useFetchPaginatedData={useFetchOrganizationsListPaginated}
        />
      </Stack>
    </>
  )
}

export default OrganizationsPage
