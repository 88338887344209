export function getValueFromLocalStorage<T = Record<string, unknown>>(
  key: string,
  defaultValue?: T
) {
  try {
    const value = window.localStorage.getItem(key)
    if (value) {
      return JSON.parse(value) as T
    } else {
      window.localStorage.setItem(key, JSON.stringify(defaultValue))
      return defaultValue
    }
  } catch (err) {
    return defaultValue
  }
}
