import React from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import {
  dashboard,
  emcAdmin,
  emcAdminUsers,
  emcAdminUsersAdd,
  forbidden,
  // forgotPassword,
  login,
  organizationAdd,
  organizationEdit,
  organizations,
  organizationSites,
  organizationSitesAdd,
  organizationSitesEdit,
  reports,
  reportsCompliance,
  siteDetails,
  siteLocationDetails,
  siteLocations,
  sites,
  userDetails,
  userInviteAccept,
  userInviteSend,
  users,
} from 'routes/routes'
import DashboardPage from 'pages/dashboard/DashboardPage'
// import ForgotPasswordPage from 'pages/login/ForgotPasswordPage'
import LoginPage from 'pages/login/LoginPage'
import SidebarNavigationLayout from 'components/templates/navigation/SidebarNavigationLayout'
import SitesPage from 'pages/sites/SitesPage'
import UsersPage from 'pages/users/UsersPage'
import UserDetailsPage from 'pages/users/UserDetailsPage'
import UserInviteSend from 'pages/users/UserInviteSend'
import UserInviteAccept from 'pages/users/UserInviteAccept'
import VisitorLayout from 'components/templates/visitor/VisitorLayout'
import LocationsPage from 'pages/locations/LocationsPage'
import SiteDetailsPage from 'pages/sites/SiteDetailsPage'
import LocationDetailsPage from 'pages/locations/LocationDetailsPage'
import OrganizationsPage from 'pages/organizations/OrganizationsPage'
import OrganizationsAddPage from 'pages/organizations/OrganizationsAddPage'
import AuthenticatedRoute from 'routes/AuthenticatedRoute'
import OrganizationSitesPage from 'pages/organizations/organization-sites/OrganizationSitesPage'
import OrganizationSitesAddSitePage from 'pages/organizations/organization-sites/OrganizationSitesAddSitePage'
import AuthorizedEMCAdminRoute from 'routes/AuthorizedEMCAdminRoute'
import AuthorizedAdminsRoute from 'routes/AuthorizedAdminsRoute'
import OrganizationSitesEditSitePage from 'pages/organizations/organization-sites/OrganizationSitesEditSitePage'
import ForbiddenPage from 'pages/ForbiddenPage'
import EMCUsersPage from 'pages/emc-admin/EMCUsersPage'
import OrganizationEditPage from 'pages/organizations/OrganizationEditPage'
import { theme } from 'flanders-common-ui'
import { Stack } from '@mui/material'
import ComplianceReportPage from 'pages/reports/ComplianceReportPage'

const App: React.FC = () => {
  return (
    <Routes>
      {/* Unauthenticated Routes */}
      <Route path="/" element={<VisitorLayout />}>
        <Route index element={<LoginPage />} />
        <Route path={login} element={<LoginPage />} />
        {/* <Route path={forgotPassword} element={<ForgotPasswordPage />} /> */}
        <Route path={userInviteAccept} element={<UserInviteAccept />} />

        <Route path="*" element={<div>Not Found</div>} />
      </Route>

      {/* Authenticated Routes */}
      <Route
        path={organizations}
        element={
          <AuthorizedEMCAdminRoute>
            <SidebarNavigationLayout variant="emcAdmin" />
          </AuthorizedEMCAdminRoute>
        }
      >
        <Route index element={<OrganizationsPage />} />
        <Route path={organizationAdd} element={<OrganizationsAddPage />} />
        <Route path={organizationEdit} element={<OrganizationEditPage />} />
        <Route path={organizationSites} element={<OrganizationSitesPage />} />
        <Route
          path={organizationSitesAdd}
          element={<OrganizationSitesAddSitePage />}
        />
        <Route
          path={organizationSitesEdit}
          element={<OrganizationSitesEditSitePage />}
        />
      </Route>

      <Route
        path={emcAdmin}
        element={
          <AuthorizedEMCAdminRoute>
            <SidebarNavigationLayout variant="emcAdmin" />
          </AuthorizedEMCAdminRoute>
        }
      >
        <Route path={emcAdminUsers} element={<EMCUsersPage />} />
        <Route path={emcAdminUsersAdd} element={<UserInviteSend />} />
      </Route>

      <Route
        path={dashboard}
        element={
          <AuthenticatedRoute>
            <SidebarNavigationLayout />
          </AuthenticatedRoute>
        }
      >
        <Route index element={<DashboardPage />} />
      </Route>

      <Route
        path={sites}
        element={
          <AuthenticatedRoute>
            <SidebarNavigationLayout />
          </AuthenticatedRoute>
        }
      >
        <Route index element={<SitesPage />} />
        <Route path={siteDetails} element={<SiteDetailsPage />} />
        <Route path={siteLocations} element={<LocationsPage />} />
        <Route path={siteLocationDetails} element={<LocationDetailsPage />} />
      </Route>

      <Route
        path={users}
        element={
          <AuthorizedAdminsRoute>
            <SidebarNavigationLayout />
          </AuthorizedAdminsRoute>
        }
      >
        <Route index element={<UsersPage />} />
        <Route path={userInviteSend} element={<UserInviteSend />} />
      </Route>

      <Route
        path={users}
        element={
          <AuthenticatedRoute>
            <SidebarNavigationLayout />
          </AuthenticatedRoute>
        }
      >
        {/* Users must have access to their own details page, regardless of role */}
        <Route path={userDetails} element={<UserDetailsPage />} />
      </Route>

      <Route
        path={'/'}
        element={
          <AuthenticatedRoute>
            <SidebarNavigationLayout />
          </AuthenticatedRoute>
        }
      >
        <Route path={forbidden} element={<ForbiddenPage />} />
      </Route>

      <Route
        path={reports}
        element={
          <AuthenticatedRoute>
            <Stack padding={theme.units.medium}>
              <Outlet />
            </Stack>
          </AuthenticatedRoute>
        }
      >
        <Route path={reportsCompliance} element={<ComplianceReportPage />} />
      </Route>
    </Routes>
  )
}

export default App
