import React, { createContext, useContext, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { forbidden } from 'routes/routes'
import { useAuth } from './useAuth'
import { useLocalStorage } from './useLocalStorage'

type ActiveOrganization =
  | {
      id: string
      name: string
    }
  | undefined
export type OrganizationContext = {
  updateActiveOrganization: (data: ActiveOrganization) => void
  activeOrganization: undefined | ActiveOrganization
}
const defaultActiveOrganization: ActiveOrganization = {
  id: '',
  name: '',
}
const initialContext = {
  updateActiveOrganization: () => {},
  activeOrganization: defaultActiveOrganization,
}

const ActiveOrganizationContext =
  createContext<OrganizationContext>(initialContext)

type ActiveOrganizationProviderParams = {
  children?: React.ReactNode
  defaultOrganizationId?: string
  defaultOrganizationName?: string
}
export const ActiveOrganizationProvider: React.FC<
  ActiveOrganizationProviderParams
> = ({
  children,
  defaultOrganizationId = '',
  defaultOrganizationName = '',
}) => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const [activeOrganization, setActiveOrganization] =
    useLocalStorage<ActiveOrganization>('activeOrganization', {
      id: defaultOrganizationId,
      name: defaultOrganizationName,
    })

  const updateActiveOrganization = (data: ActiveOrganization) => {
    if (
      user?.user.role !== 'emc-admin' &&
      data?.id !== user?.user.organizationId
    ) {
      navigate(forbidden, { replace: true })
    } else {
      setActiveOrganization({
        id: data?.id ?? '',
        name: data?.name ?? '',
      })
    }
  }

  useEffect(() => {
    // Set active org upon login
    const usersOrganizationId = user?.user.organizationId ?? ''

    if (typeof user === 'undefined') {
      setActiveOrganization(undefined)
    } else if (usersOrganizationId && !activeOrganization?.id) {
      setActiveOrganization({
        id: usersOrganizationId,
        name: activeOrganization?.name ?? '',
      })
    }
  }, [user?.user])

  const value = useMemo(
    () => ({
      updateActiveOrganization,
      activeOrganization,
    }),
    [updateActiveOrganization, activeOrganization]
  )

  return (
    <ActiveOrganizationContext.Provider value={value}>
      {children}
    </ActiveOrganizationContext.Provider>
  )
}

export const useActiveOrganizationContext = () => {
  return useContext(ActiveOrganizationContext)
}
