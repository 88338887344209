import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'
import App from './App'

import { AxiosError } from 'axios'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AuthProvider } from 'hooks/useAuth'
import './index.css'
import { theme, ToastAlertProvider } from 'flanders-common-ui'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { ActiveOrganizationProvider } from 'hooks/useActiveOrganization'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount: number, error: unknown) => {
        const response = (error as AxiosError).response
        return ![401, 404].includes(response?.status ?? 0) && failureCount < 3
      },
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <CssBaseline />
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <ActiveOrganizationProvider>
                <ToastAlertProvider>
                  <App />
                </ToastAlertProvider>
              </ActiveOrganizationProvider>
            </AuthProvider>
          </QueryClientProvider>
        </BrowserRouter>
      </LocalizationProvider>
    </ThemeProvider>
  </StrictMode>
)
