import { apiClient, APIFunction, GenericErrorResponse } from 'api/http-common'
import { mapUserResponseToUser } from './mapUserResponseToUser'
import { User, UserResponse } from './users-types'

type PutUpdatePasswordParams = {
  userId: string
  passwordCurrent: string
  passwordNew: string
}

export const putUpdatePassword: APIFunction<
  PutUpdatePasswordParams,
  User
> = async ({ userId, passwordCurrent, passwordNew }) => {
  try {
    const response = await apiClient.put<UserResponse>(
      `/users/${userId}/change_password`,
      {
        password_old: passwordCurrent,
        password_new: passwordNew,
      }
    )

    return mapUserResponseToUser(response.data)
  } catch (err: unknown) {
    const axiosError = err as GenericErrorResponse
    const { data } = axiosError.response ?? {}

    throw data?.message ?? []
  }
}
