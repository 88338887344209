import {
  apiClient,
  APICurriedFunction,
  GenericErrorResponse,
} from 'api/http-common'
import type { TestCyclesResponse } from 'api/tests'

export type TestCyclesParams = {
  organizationId: string
  siteId: string
}

export const fetchTestCycles: APICurriedFunction<
  TestCyclesParams,
  TestCyclesResponse
> = (params) => async () => {
  try {
    const response = await apiClient.get<TestCyclesResponse>(
      `/organizations/${params.organizationId}/sites/${params.siteId}/test_cycles`
    )

    return response.data
  } catch (error) {
    const axiosError = error as GenericErrorResponse
    const { data } = axiosError.response ?? {}
    const defaultErrorMessage = 'Error fetching test_cycles data'

    console.error(defaultErrorMessage, error)

    throw data?.message ?? [defaultErrorMessage]
  }
}
