import React from 'react'
import { Stack } from '@mui/material'
import { ContentHeader } from 'flanders-common-ui'

const ForbiddenPage: React.FC = () => {
  return (
    <Stack
      alignItems="center"
      boxShadow={2}
      spacing={2}
      margin="auto"
      maxWidth={375}
      width="100%"
      sx={{ backgroundColor: 'white', borderRadius: 2 }}
    >
      <ContentHeader title="Permission Denied" body="403: Forbidden" />
    </Stack>
  )
}

export default ForbiddenPage
