import React, { PropsWithChildren } from 'react'
import { forbidden } from './routes'
import { Role } from 'api/users'
import AuthorizedRoleRoute from './AuthorizedRoleRoute'

const AuthorizedEMCAdminRoute: React.FC<PropsWithChildren> = ({
  children = null,
}) => {
  const emcAdminRole: Role = 'emc-admin'
  const data = {
    children: children,
    authorizedRoles: [emcAdminRole],
    toRoute: forbidden,
  }

  return <AuthorizedRoleRoute {...data} />
}

export default AuthorizedEMCAdminRoute
