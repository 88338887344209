import {
  LocationTestType,
  ScheduledTestType,
  TestStatus,
  useFetchTestCyclesResults,
} from 'api/tests'
import { useToastAlertProvider } from 'flanders-common-ui'
import { useActiveOrganizationContext } from 'hooks/useActiveOrganization'
import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import ComplianceReport from './ComplianceReport'

//TODO: Revisit this, it's not in use but might be important for when the API returns live data
export type ReportResultType = {
  id: string
  locationName: string
  scheduledType: ScheduledTestType
  status: TestStatus
  testType: LocationTestType
}

const ComplianceReportPage: React.FC = () => {
  const { setToast } = useToastAlertProvider()
  const [params] = useSearchParams()
  const { activeOrganization } = useActiveOrganizationContext()
  const beginningCycleId = params.get('beginning_cycle_id') ?? ''
  const endingCycleId = params.get('ending_cycle_id') ?? ''
  const siteId = params.get('site_id') ?? ''

  const {
    data: testCycleResults,
    isLoading: isLoadingTestCyclesResults,
    error: errorFetchingTestCyclesResults,
  } = useFetchTestCyclesResults({
    beginningCycleId: beginningCycleId,
    endingCycleId: endingCycleId,
    organizationId: activeOrganization?.id ?? '',
    siteId: siteId,
  })

  useEffect(() => {
    if (errorFetchingTestCyclesResults) {
      setToast({
        message: 'Error fetching compliance report data',
        severity: 'error',
      })
    }
  }, [errorFetchingTestCyclesResults, setToast])

  const gatewayId = testCycleResults ? testCycleResults[0]?.gatewayId : ''

  return (
    <ComplianceReport
      testCycleResults={testCycleResults}
      isLoading={isLoadingTestCyclesResults}
      gatewayId={gatewayId}
    />
  )
}

export default ComplianceReportPage
