import { SiteStatus } from './sites-types'

export type SiteStatusRelatedAttributes = {
  last_heartbeat_at?: Date | null
  offline_days_count?: number
  requires_attention_count: number
}

export const deriveSiteStatusFromResponse = (
  response: SiteStatusRelatedAttributes
): SiteStatus => {
  if (response.requires_attention_count) {
    return 'requires_attention'
  } else if (response.last_heartbeat_at === null) {
    //TODO: Check if last_heartbeat_at is too long ago
    return 'gateway_offline'
  } else if (response.offline_days_count) {
    return 'gateway_offline'
  } else {
    return 'gateway_online'
  }
}
