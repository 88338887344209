import React from 'react'
import { dashboard, emcAdminUsers, organizations, sites, users } from './routes'
import {
  BusinessOutlined,
  DashboardOutlined,
  PeopleOutlineOutlined,
  SvgIconComponent,
} from '@mui/icons-material'

export interface RouteLabel {
  path: string
  label: React.ReactNode | string
  Icon?: SvgIconComponent | (() => JSX.Element)
}

export type NavRoute<T> = {
  key: T
  path: string
  label: string
  Icon?: SvgIconComponent | (() => JSX.Element)
}

export type NavProps<T> = {
  navRoutes: NavRoute<T>[]
}
export type EMCAdminNavProps<T> = {
  navRoutes: NavRoute<T>[]
}

export type Label = 'Dashboard' | 'Sites' | 'Users' | 'UsersInvite'
export type EMCAdminLabel = 'Organizations' | 'EMCUsers'

export const ViewerRouteLabels: NavRoute<Label>[] = [
  {
    key: 'Dashboard',
    Icon: DashboardOutlined,
    label: 'Dashboard',
    path: dashboard,
  },
  {
    key: 'Sites',
    Icon: BusinessOutlined,
    label: 'Sites',
    path: sites,
  },
]

export const RouteLabels: NavRoute<Label>[] = [
  {
    key: 'Dashboard',
    Icon: DashboardOutlined,
    label: 'Dashboard',
    path: dashboard,
  },
  {
    key: 'Sites',
    Icon: BusinessOutlined,
    label: 'Sites',
    path: sites,
  },
  {
    key: 'Users',
    Icon: PeopleOutlineOutlined,
    label: 'Users',
    path: users,
  },
]

export const EMCAdminRouteLabels: NavRoute<EMCAdminLabel>[] = [
  {
    key: 'Organizations',
    Icon: DashboardOutlined,
    label: 'Organizations',
    path: organizations,
  },
  {
    key: 'EMCUsers',
    Icon: PeopleOutlineOutlined,
    label: 'EMC Users',
    path: emcAdminUsers,
  },
]
