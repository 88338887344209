import { getLocationNodeStatusChipProps } from 'utils/getLocationNodeStatusChipProps'
import type { FixtureType, Location } from 'api/locations'

export const locationStatusSortComparator = (
  location1: Location,
  location2: Location
) => {
  const location1Status = getLocationNodeStatusChipProps(location1).label
  const location2Status = getLocationNodeStatusChipProps(location2).label

  return location1Status.localeCompare(location2Status)
}

export const locationFixtureTypeComparator = (
  fixtureType1?: FixtureType,
  fixtureType2?: FixtureType
) => {
  if (!fixtureType1 && fixtureType2) {
    return Infinity
  } else if (!fixtureType2) {
    return -Infinity
  } else {
    return fixtureType1?.localeCompare(fixtureType2) ?? 0
  }
}
