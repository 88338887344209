import { apiClient, APICurriedFunction } from 'api/http-common'
import { Page, PageRequestParams } from 'api/page'
import { mapOrganizationResponseToOrganization } from './mapOrganizationResponseToOrganization'
import { Organization, OrganizationResponse } from './organization-types'

export type OrganizationsListRequestParams = PageRequestParams & {
  requiring_attention?: boolean
}

export const fetchOrganizationsListPaginated: APICurriedFunction<
  OrganizationsListRequestParams,
  Page<Organization>
> = (requestParams) => async () => {
  const { data: organizationsPageResponse } = await apiClient.get<
    Page<OrganizationResponse>
  >('/organizations', {
    params: requestParams,
  })

  return {
    ...organizationsPageResponse,
    entries: organizationsPageResponse.entries.map(
      mapOrganizationResponseToOrganization
    ),
  }
}
