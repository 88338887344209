import { GenericErrorResponse } from 'api/http-common'
import { User } from 'api/users'
import { useQuery } from 'react-query'
import { fetchUserById } from './fetchUserById'

export const useFetchUserById = (userId: string) => {
  return useQuery<User, GenericErrorResponse>(
    ['fetchUserById', userId],
    fetchUserById(userId)
  )
}
