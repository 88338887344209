import React from 'react'
import SuccessLayout from 'components/templates/success/SuccessLayout'
import { PersonOutlineOutlined } from '@mui/icons-material'

export type UserInviteSentProps = {
  handleClickFinish: () => void
  handleClickSecondary: () => void
}
const UserInviteSent: React.FC<UserInviteSentProps> = ({
  handleClickFinish,
  handleClickSecondary,
}) => {
  return (
    <SuccessLayout
      handleClickPrimary={handleClickFinish}
      heading="New user can log in with the password: 'This is an RRA User'"
      handleClickSecondary={handleClickSecondary}
      icon={<PersonOutlineOutlined width={96} height={96} />}
      primaryLabel="Finish"
      secondaryLabel="Add Another User"
      subtitle="For security, ask them to change this password as soon as possible."
    />
  )
}

export default UserInviteSent
