import {
  apiClient,
  APICurriedFunction,
  GenericErrorResponse,
} from 'api/http-common'
import type {
  OrganizationOverview,
  OrganizationOverviewResponse,
} from './organization-types'

export const fetchOrganizationOverviewById: APICurriedFunction<
  string,
  OrganizationOverview
> = (organizationId) => async () => {
  try {
    const response = await apiClient.get<OrganizationOverviewResponse>(
      `/organizations/${organizationId}/overview`
    )

    return {
      healthy: response.data.healthy_count,
      requiringAttention: response.data.requiring_attention_count,
    }
  } catch (error) {
    const axiosError = error as GenericErrorResponse
    const { data } = axiosError.response ?? {}
    const defaultErrorMessage = 'Error fetching overview data'

    console.error(defaultErrorMessage, error)

    throw data?.message ?? [defaultErrorMessage]
  }
}
