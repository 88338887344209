import { apiClient, GenericErrorResponse } from 'api/http-common'
import type { APICurriedFunction } from 'api/http-common'
import type { TestCycleResultsResponse, TestCycleResults } from 'api/tests'
import { mapTestCyclesResultResponseToTestCyclesResult } from './mapTestCyclesResultResponseToTestCyclesResult'

export type TestCyclesResultsRequestParams = {
  beginningCycleId: string
  endingCycleId: string
  organizationId: string
  siteId: string
}

export const fetchTestCyclesResults: APICurriedFunction<
  TestCyclesResultsRequestParams,
  TestCycleResults[]
> = (params: TestCyclesResultsRequestParams) => async () => {
  if (!params.beginningCycleId || !params.endingCycleId) {
    throw ['beginning_cycle_id and ending_cycle_id cannot be empty']
  }

  try {
    const { data, headers } = await apiClient.get<TestCycleResultsResponse[]>(
      `/organizations/${params.organizationId}/sites/${params.siteId}/test_cycles_results`,
      {
        params: {
          beginning_cycle_id: params.beginningCycleId,
          ending_cycle_id: params.endingCycleId,
        },
      }
    )

    return data
      .map((response) => ({
        ...response,
        gateway_id: headers['flanders-gateway-firmware-serial-number'] ?? '',
      }))
      .map(mapTestCyclesResultResponseToTestCyclesResult)
  } catch (error) {
    const axiosError = error as GenericErrorResponse
    const { data } = axiosError.response ?? {}
    const defaultErrorMessage = 'Error fetching test_cycles_results data'

    console.error(defaultErrorMessage, error)

    throw data?.message ?? [defaultErrorMessage]
  }
}
