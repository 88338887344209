import React from 'react'
import { Grid, Stack } from '@mui/material'
import { formatDate, SHORT_MONTH_YEAR_DATE_TIME_FORMAT } from 'utils/date-utils'
import { theme, Typography } from 'flanders-common-ui'
import styled from 'styled-components'

const StyledSectionLabel = styled.div`
  background-color: ${theme.palette.UI.light};
  padding: 0 5px;
  position: absolute;
  top: -10px;
`

type TestingCycleSectionHeaderProps = {
  beginningAt: string | Date
  endingAt: string | Date | null
  locationsTestedCount: number
  requiresAttentionCount: number
}
const TestingCycleSectionHeader: React.FC<TestingCycleSectionHeaderProps> = ({
  beginningAt,
  endingAt,
  locationsTestedCount,
  requiresAttentionCount,
}) => {
  return (
    <Grid container item justifyContent={'space-between'} width={'100%'}>
      <Stack marginTop={1}>
        <Typography variant="h2" weight="bold" fontSize={14}>
          TEST CYCLE STARTED:
          <Typography fontSize={24} weight="semiBold">
            {formatDate(beginningAt)}
          </Typography>
        </Typography>
        <Typography color={theme.palette.grey[600]}>
          {endingAt === null
            ? `Results current through ${formatDate(
                Date.now(),
                SHORT_MONTH_YEAR_DATE_TIME_FORMAT
              )}`
            : `Cycle ended on ${formatDate(endingAt)}`}
        </Typography>
      </Stack>

      <Stack
        border={'solid 1px'}
        borderRadius={2}
        borderColor={theme.palette.grey[600]}
        padding={2}
        position="relative"
      >
        <Grid container item justifyContent={'center'}>
          <StyledSectionLabel>
            <Typography
              variant="h2"
              fontSize={14}
              color={theme.palette.grey[600]}
            >
              Test Cycle Overview
            </Typography>
          </StyledSectionLabel>
        </Grid>
        <Grid container item justifyContent={'space-between'} columnGap={2}>
          <Stack alignItems={'center'}>
            <Typography fontSize={24} weight="semiBold">
              {locationsTestedCount}
            </Typography>
            <Typography
              variant="bodyXs"
              fontSize={13}
              style={{ textTransform: 'uppercase' }}
            >
              Locations Tested
            </Typography>
          </Stack>
          <Stack alignItems={'center'}>
            <Typography fontSize={24} weight="semiBold">
              {requiresAttentionCount}
            </Typography>
            <Typography fontSize={13} style={{ textTransform: 'uppercase' }}>
              Required Attention
            </Typography>
          </Stack>
        </Grid>
      </Stack>
    </Grid>
  )
}

export default TestingCycleSectionHeader
