import React, { useState } from 'react'
import { Alert, Stack } from '@mui/material'
// import { useNavigate } from 'react-router-dom'
// import { forgotPassword } from 'routes/routes'
import { useAuth } from 'hooks/useAuth'
import {
  // Button,
  LoginForm,
  LoginFormData,
  ContentHeader,
} from 'flanders-common-ui'

const LoginPage: React.FC = () => {
  // const navigate = useNavigate()
  const { login } = useAuth()
  const [errors, setError] = useState<string[]>([])

  const handleSubmit = async (userData: LoginFormData) => {
    try {
      await login({ ...userData })
    } catch (err) {
      const messages = err as string[]
      setError(messages ?? 'Authentication Error')
    }
  }

  return (
    <Stack
      alignItems="center"
      boxShadow={2}
      spacing={2}
      maxWidth={375}
      width="100%"
      sx={{ backgroundColor: 'white', borderRadius: 2 }}
    >
      <ContentHeader title="Login" body="Welcome to ELTS!" />

      <Stack width="100%">
        <LoginForm onSubmit={handleSubmit} />

        {/* <Stack alignItems="center" spacing={1} marginBottom={3}>
          <Button variant="text" onClick={() => navigate(forgotPassword)}>
            Forgot Password
          </Button>
        </Stack> */}

        {errors.map((error) => (
          <Alert
            key={error}
            severity="error"
            onClose={() => {
              setError(errors.filter((prevError) => prevError !== error))
            }}
          >
            {error}
          </Alert>
        ))}
      </Stack>
    </Stack>
  )
}

export default LoginPage
