import { apiClient, GenericErrorResponse } from 'api/http-common'
import type { APICurriedFunction } from 'api/http-common'
import type { Location, LocationResponse } from './location-types'
import { mapLocationResponseToLocation } from 'api/locations'

export type LocationDetailsRequestParams = {
  locationId: string
  organizationId: string
}

export const fetchLocationById: APICurriedFunction<
  LocationDetailsRequestParams,
  Location
> = (params) => async () => {
  try {
    const response = await apiClient.get<LocationResponse>(
      `/organizations/${params.organizationId}/locations/${params.locationId}`
    )

    return mapLocationResponseToLocation(response.data)
  } catch (error) {
    const axiosError = error as GenericErrorResponse
    const { data } = axiosError.response ?? {}
    const defaultErrorMessage = 'Error fetching Location data'

    console.error(defaultErrorMessage, error)

    throw data?.message ?? [defaultErrorMessage]
  }
}
