import React from 'react'
import { useParams } from 'react-router-dom'
import { Divider, Grid, Paper, Stack, useMediaQuery } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import {
  AboveFoldLayout,
  ContentHeader,
  Duration30SecondIcon,
  NoLocationsIconLG,
  theme,
  Duration90MinuteIcon,
  Typography,
} from 'flanders-common-ui'
import { RouteLabels } from 'routes/route-labels'
import { useFetchLocationById } from 'api/locations'
import {
  useFetchTestHistoryByLocationId,
  useFetchUpcomingTestsByLocationId,
} from 'api/tests'
import type { TestHistory } from 'api/tests/test-types'
import DataGrid from 'components/molecules/DataGrid'
import { formatDateOnly, formatTimeOnly } from 'utils/date-utils'
import {
  testResultDateComparator,
  testResultStatusComparator,
  testResultTestTypeComparator,
} from 'utils/test-result-comparators'
import TestStatusColumn from 'components/organisms/tests/TestStatusColumn'
import LocationDetailsPanel from 'components/molecules/LocationDetailsPanel'
import UpcomingTestsPanel from 'components/organisms/tests/UpcomingTestsPanel'
import { useActiveOrganizationContext } from 'hooks/useActiveOrganization'

function useColumns(): GridColDef<TestHistory>[] {
  const columns: GridColDef<TestHistory>[] = [
    {
      field: 'startedAt',
      flex: 1,
      headerName: 'Date/Time',
      valueGetter: (params) => params.row,
      sortComparator: testResultDateComparator,
      renderCell: ({ row: testHistoryData }) => {
        const formattedDate = testHistoryData.rescheduledFor
          ? `${formatDateOnly(testHistoryData.rescheduledFor)}*`
          : formatDateOnly(testHistoryData.startedAt)
        const formattedTime = testHistoryData.rescheduledFor
          ? formatTimeOnly(testHistoryData.rescheduledFor)
          : formatTimeOnly(testHistoryData.startedAt)

        return (
          <Stack flexDirection={'column'}>
            <Typography color={theme.palette.grey[900]} weight="semiBold">
              {formattedDate}
            </Typography>
            <Typography variant="bodySmall" color={theme.palette.grey[700]}>
              {formattedTime}
            </Typography>
          </Stack>
        )
      },
    },
    {
      field: 'testType',
      flex: 1,
      headerName: 'Type',
      valueGetter: (params) => params.row,
      sortComparator: testResultTestTypeComparator,
      renderCell: ({ row: testHistoryData }) => {
        return (
          <>
            {testHistoryData.testType === '30sec' ? (
              <Duration30SecondIcon sx={{ marginRight: 1 }} />
            ) : (
              <Duration90MinuteIcon sx={{ marginRight: 1 }} />
            )}
            <Typography variant="bodySmall">
              {testHistoryData.scheduledType === 'manual_location' ||
              testHistoryData.scheduledType === 'manual_sitewide'
                ? 'Manual'
                : 'Automatic'}
            </Typography>
          </>
        )
      },
    },
    {
      field: 'status',
      flex: 1,
      headerName: 'Result',
      valueGetter: (params) => params.row,
      sortComparator: testResultStatusComparator,
      renderCell: ({ row: testHistoryData }) => {
        if (testHistoryData.status === null) {
          return <div>--</div>
        } else {
          return (
            <TestStatusColumn
              testHistoryData={testHistoryData}
              testId={`location-details-test-history-status-chip-${testHistoryData.id.toString()}`}
            />
          )
        }
      },
    },
  ]

  return columns
}

const LocationDetailsPage: React.FC = () => {
  const { locationId = '', siteId = '' } = useParams<{
    locationId: string
    siteId: string
  }>()
  const { activeOrganization } = useActiveOrganizationContext()
  const { isLoading: isLoadingLocationDetails, data: locationDetailsData } =
    useFetchLocationById({
      locationId: locationId,
      organizationId: activeOrganization?.id ?? '',
    })
  const { data: upcomingTestsData, isLoading: isLoadingUpcomingTests } =
    useFetchUpcomingTestsByLocationId({
      locationId: locationId,
      organizationId: activeOrganization?.id ?? '',
      siteId: siteId,
      page: 1,
      page_size: 100,
    })
  const columns = useColumns()
  const hasTests = locationDetailsData?.lastTest !== null
  const viewportIsLgOrMore = useMediaQuery(theme.breakpoints.up('lg'))

  return isLoadingLocationDetails || !locationDetailsData ? (
    <>Loading</>
  ) : (
    <Grid container spacing={1}>
      <AboveFoldLayout
        breadcrumbRoutes={[RouteLabels[1]]}
        heading={locationDetailsData.name}
      />

      <Grid container item spacing={2}>
        <Grid item xs={12} lg={8}>
          <Paper
            sx={{
              px: 2,
              paddingTop: '20px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Stack
              spacing={2}
              sx={{
                flex: 1,
              }}
            >
              <Typography variant="h3" weight="bold">
                Testing History
              </Typography>

              <Divider variant="middle" />

              {!hasTests ? (
                <Stack
                  minHeight={500}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <ContentHeader
                    title="No Testing Records Found"
                    body="This location has not run any tests yet."
                  />
                  <NoLocationsIconLG />
                </Stack>
              ) : (
                <DataGrid<TestHistory>
                  columns={columns}
                  dataId="location-test-history-details"
                  requestExtraParams={{
                    locationId: locationDetailsData.id,
                    organizationId: activeOrganization?.id,
                    siteId: siteId,
                  }}
                  useFetchPaginatedData={useFetchTestHistoryByLocationId}
                />
              )}
            </Stack>
          </Paper>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Stack spacing={2} maxWidth={viewportIsLgOrMore ? 375 : 'none'}>
            <UpcomingTestsPanel
              body=""
              data={upcomingTestsData}
              heading="Upcoming Tests"
              isLoading={isLoadingUpcomingTests}
              title="Upcoming Tests"
            />

            <LocationDetailsPanel
              locationFixture={locationDetailsData.fixture}
              locationNode={locationDetailsData.node}
              notes={locationDetailsData.notes}
            />
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LocationDetailsPage
