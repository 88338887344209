import { apiClient } from 'api/http-common'
import type { APIFunction, GenericErrorResponse } from 'api/http-common'
import { mapUserResponseToUser } from './mapUserResponseToUser'
import { User, UserResponse } from './users-types'

export const deleteUser: APIFunction<string, User> = async (id) => {
  try {
    const response = await apiClient.delete<UserResponse>(`/users/${id}`)

    return mapUserResponseToUser(response.data)
  } catch (err: unknown) {
    const axiosError = err as GenericErrorResponse
    const { data } = axiosError.response ?? {}

    throw data?.message ?? []
  }
}
