import { apiClient } from 'api/http-common'
import type { APIFunction, GenericErrorResponse } from 'api/http-common'
import { Site, SiteResponse } from './sites-types'
import { mapSiteResposeToSite } from './mapSiteResposeToSite'

type OrganizationSiteDeleteParams = {
  organizationId: string
  siteId: string
}

export const deleteOrganizationSite: APIFunction<
  OrganizationSiteDeleteParams,
  Site
> = async ({ organizationId, siteId }) => {
  try {
    const response = await apiClient.delete<SiteResponse>(
      `organizations/${organizationId}/sites/${siteId}`
    )

    return mapSiteResposeToSite(response.data)
  } catch (err: unknown) {
    const axiosError = err as GenericErrorResponse
    const { data } = axiosError.response ?? {}

    throw data?.message ?? []
  }
}
