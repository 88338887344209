import { TestHistory } from 'api/tests'
import moment from 'moment'

const sortNullTestsFirst = (
  testHistory1?: TestHistory,
  testHistory2?: TestHistory
) => {
  // Null lastTests are sorted higher
  if (!testHistory1 && testHistory2) {
    return Infinity
  } else if (!testHistory2) {
    return -Infinity
  } else {
    return undefined
  }
}

export const testResultDateComparator = (
  testHistory1: TestHistory,
  testHistory2: TestHistory
) => {
  if (testHistory1.rescheduledFor && testHistory2.rescheduledFor) {
    return moment(testHistory1.rescheduledFor).diff(testHistory1.rescheduledFor)
  } else if (testHistory1.rescheduledFor && !testHistory2.rescheduledFor) {
    return moment(testHistory1.rescheduledFor).diff(testHistory2.startedAt)
  } else if (!testHistory1.rescheduledFor && testHistory2.rescheduledFor) {
    return moment(testHistory1.startedAt).diff(testHistory2.rescheduledFor)
  } else {
    return moment(testHistory1.startedAt).diff(testHistory2.startedAt)
  }
}

export const testResultStartDateComparator = (
  testHistory1: TestHistory,
  testHistory2: TestHistory
): number => {
  return (
    sortNullTestsFirst(testHistory1, testHistory2) ??
    moment(testHistory1.startedAt).diff(testHistory2.startedAt)
  )
}

export const testResultTestTypeComparator = (
  testHistory1: TestHistory,
  testHistory2: TestHistory
): number => {
  return (
    sortNullTestsFirst(testHistory1, testHistory2) ??
    testHistory1.testType.localeCompare(testHistory2.testType)
  )
}

export const testResultStatusComparator = (
  testHistory1: TestHistory,
  testHistory2: TestHistory
): number => {
  return (
    sortNullTestsFirst(testHistory1, testHistory2) ??
    testHistory1.status.localeCompare(testHistory2.status)
  )
}
