import moment from 'moment-timezone'
import type { Moment, MomentInput } from 'moment'
import { TimeWindow } from 'flanders-common-ui'

export const SHORT_MONTH_DATETIME_FORMAT = 'MMM D, hh:mm a'
export const SHORT_MONTH_DATE_FORMAT = 'MMM D'
export const SHORT_MONTH_YEAR_DATE_TIME_FORMAT = 'MMM D, YYYY hh:mm a'
export const DATEPICKER_DATETIME_FORMAT = 'MM/DD/YYYY hh:mm A'
export const SHORT_MONTH_YEAR_DATE_FORMAT = 'MMM D, YYYY'

const dateToLocalTime = (
  dateInput: moment.MomentInput,
  timezone: string
): Moment => moment.utc(dateInput).tz(timezone)

export const formatDate = (
  dateInput: MomentInput,
  format = SHORT_MONTH_DATETIME_FORMAT,
  timezone = moment.tz.guess()
): string => {
  return dateToLocalTime(dateInput, timezone) //Convert date to local time
    .format(format)
}

export const formatDateOnly = (
  dateInput: MomentInput,
  format = 'MMM D, YYYY',
  timezone = moment.tz.guess()
): string => {
  return dateToLocalTime(dateInput, timezone) //Convert date to local time
    .format(format)
}

export const formatTimeOnly = (
  dateInput: MomentInput,
  format = 'hh:mm a',
  timezone = moment.tz.guess()
): string => {
  return dateToLocalTime(dateInput, timezone) //Convert date to local time
    .format(format)
}

export const mergeDateTimeWindow = (
  scheduledDate: Moment,
  timeWindow: TimeWindow
): Date => {
  const mergedDateTime = moment(scheduledDate).set('hour', timeWindow.value)
  return moment.utc(mergedDateTime).toDate()
}

export const isWithin24HoursOfDate = (
  dateInput: MomentInput,
  dateToCompare: MomentInput,
  timezone = moment.tz.guess()
) => {
  return dateToLocalTime(dateInput, timezone).isBetween(
    dateToLocalTime(moment(dateToCompare), timezone).subtract(24, 'hours'),
    dateToLocalTime(moment(dateToCompare), timezone).add(24, 'hours'),
    'hours',
    '[]' // both dates are inclusive https://momentjs.com/docs/#/query/is-between/
  )
}

export const isWithinNext24Hours = (
  dateInput: MomentInput,
  timezone = moment.tz.guess()
) => {
  return dateToLocalTime(dateInput, timezone).isBetween(
    new Date(),
    dateToLocalTime(moment(new Date()), timezone).add(24, 'hours'),
    'hours',
    '[]' // both dates are inclusive https://momentjs.com/docs/#/query/is-between/
  )
}

export const isWithinNext30Days = (
  dateInput: MomentInput,
  timezone = moment.tz.guess()
) => {
  return dateToLocalTime(dateInput, timezone).isBetween(
    new Date(),
    dateToLocalTime(moment(new Date()), timezone).add(30, 'days'),
    'hours',
    '[]' // both dates are inclusive https://momentjs.com/docs/#/query/is-between/
  )
}

export const dateTimeFromNow = (
  dateInput: MomentInput,
  timezone = moment.tz.guess()
): string => {
  const localDateTime = dateToLocalTime(dateInput, timezone)
  return moment(localDateTime).fromNow()
}

export const thirtyDaysFromNow = () => {
  const future = new Date()
  future.setDate(future.getDate() + 30)
  return moment(future)
}
