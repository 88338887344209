import {
  APICurriedFunction,
  apiClient,
  GenericErrorResponse,
} from 'api/http-common'
import { Page, PageRequestParams } from 'api/page'
import type { Location, LocationResponse } from 'api/locations'
import { mapLocationResponseToLocation } from 'api/locations'

export type LocationsListRequestParams = PageRequestParams & {
  organizationId?: string
  requiringAttention?: boolean
}

export const fetchLocationsListPaginated: APICurriedFunction<
  LocationsListRequestParams,
  Page<Location>
> = (rawParams: LocationsListRequestParams) => async () => {
  try {
    if (!rawParams.organizationId) {
      throw new Error('Invalid organization')
    }

    const params = {
      page: rawParams.page,
      page_size: rawParams.page_size,
      ...(rawParams.requiringAttention === true && {
        requiring_attention: true,
      }),
    }
    const response = await apiClient.get<Page<LocationResponse>>(
      `/organizations/${rawParams.organizationId}/locations`,
      {
        params,
      }
    )

    return {
      ...response.data,
      entries: response.data.entries.map(mapLocationResponseToLocation),
    }
  } catch (err: unknown) {
    const axiosError = err as GenericErrorResponse
    const { data } = axiosError.response ?? {}

    throw data?.message ?? ['Fetch Locations List Error']
  }
}
