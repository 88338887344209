import {
  apiClient,
  APICurriedFunction,
  GenericErrorResponse,
} from 'api/http-common'
import { mapUserResponseToUser } from './mapUserResponseToUser'
import type { User, UserResponse } from './users-types'

export type UserByIdRequestParams = {
  id: string
}

export const fetchUserById: APICurriedFunction<string, User> =
  (id: string) => async () => {
    try {
      const response = await apiClient.get<UserResponse>(`/users/${id}`)

      return mapUserResponseToUser(response.data)
    } catch (error) {
      const axiosError = error as GenericErrorResponse
      const { data } = axiosError.response ?? {}
      const defaultErrorMessage = 'Error fetching user data'

      console.error(defaultErrorMessage, error)

      throw data?.message ?? [defaultErrorMessage]
    }
  }
