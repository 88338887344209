import React from 'react'
import { Stack } from '@mui/material'
import { theme, Typography } from 'flanders-common-ui'
import {
  isTestErrorResult,
  SensorResults,
  testErrorMappings,
} from 'api/tests/test-types'
import { StatusIcon } from 'flanders-common-ui'

type TestFailureProps = {
  sensorResults?: SensorResults[]
}

const TestFailure: React.FC<TestFailureProps> = ({ sensorResults = [] }) => {
  const sensorResult = sensorResults.find((result) =>
    isTestErrorResult(result.message)
  )

  const sensorResultError =
    sensorResult && testErrorMappings[sensorResult.message]
      ? testErrorMappings[sensorResult.message]
      : 'Sensor Error'

  return (
    <Stack
      width={'100%'}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack>
        <Typography
          color={theme.palette.error.main}
          weight={'semiBold'}
          marginBottom={1}
          variant="body"
        >
          Test Failed
        </Typography>
        <Typography
          color={theme.palette.grey[400]}
          fontStyle="italic"
          variant="body"
        >
          {sensorResultError}
        </Typography>
      </Stack>
      <StatusIcon status={sensorResult?.message ?? 'invalid_sensor_data'} />
    </Stack>
  )
}

export default TestFailure
export type { TestFailureProps }
