//Top Level Routes
export const dashboard = '/dashboard'
export const emcAdmin = `/emc-admin`
export const emcAdminUsers = `${emcAdmin}/users`
export const emcAdminUsersAdd = `${emcAdmin}/users/add`
export const forbidden = '/403'
export const forgotPassword = '/forgot-password'
export const locations = '/locations'
export const login = '/login'
export const organizations = '/organizations'
export const organizationAdd = `${organizations}/add`
export const organizationEdit = `${organizations}/:organizationId/edit`
export const organizationSites = `${organizations}/:organizationId/sites`
export const organizationSitesAdd = `${organizations}/:organizationId/sites/add`
export const organizationSitesEdit = `${organizations}/:organizationId/sites/:siteId/edit`
export const reports = '/reports'
export const reportsCompliance = `${reports}/compliance`
export const users = '/users'
export const userInviteSend = `${users}/invite-send`
export const userInviteAccept = `${users}/invite-accept`
export const userDetails = `${users}/:userId`
export const sites = '/sites'
export const siteDetails = `${sites}/:siteId`
export const siteLocations = `${siteDetails}/locations`
export const siteLocationDetails = `${siteLocations}/:locationId`
