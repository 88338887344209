import React from 'react'
import { CancelOutlined, CheckCircleOutline } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { DetailsPanel, theme, Attribute } from 'flanders-common-ui'
import { dateTimeFromNow } from 'utils/date-utils'
import { Site } from 'api/sites'

type SiteDetailsPanelProps = {
  site: Site
}

const SiteDetailsPanel: React.FC<SiteDetailsPanelProps> = ({ site }) => {
  const address2 = `${site.city}, ${site.state} ${site.postalCode}`
  return (
    <DetailsPanel heading="Site Details">
      <Grid container direction="column">
        <Grid
          borderBottom={'solid 1px'}
          borderColor={theme.palette.UI.main}
          container
          gap={1}
          marginBottom={2}
          paddingBottom={2}
        >
          <Attribute
            label="Gateway Status"
            value={site.status == 'gateway_offline' ? 'Offline' : 'Online'}
            icon={
              site.status == 'gateway_offline' ? (
                <CancelOutlined
                  color="error"
                  data-testid="cancel-icon"
                  sx={{ width: '16px', height: '16px', marginLeft: '2px' }}
                />
              ) : (
                <CheckCircleOutline
                  color="success"
                  data-testid="check-icon"
                  sx={{ width: '16px', height: '16px', marginLeft: '2px' }}
                />
              )
            }
          />
          <Attribute
            label="Last Updated"
            value={site.updatedAt ? dateTimeFromNow(site.updatedAt) : 'N/A'}
          />
          <Grid container direction="column">
            <Attribute label="Gateway ID Number" value={site.gatewayId} />
          </Grid>
        </Grid>

        <Grid container gap={1} paddingBottom={2}>
          <Attribute
            label="Site Address"
            value={site.address}
            value2={address2}
          />
        </Grid>
      </Grid>
    </DetailsPanel>
  )
}

export default SiteDetailsPanel
