import React, { useEffect, useState } from 'react'
import { Paper, Stack } from '@mui/material'
import UserEdit from 'components/organisms/forms/UserEdit'
import { Role, useFetchUserById } from 'api/users'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth, useAuthenticatedUser } from 'hooks/useAuth'
import { forbidden, users } from 'routes/routes'
import { putUser } from 'api/users/putUser'
import { useToastAlertProvider } from 'flanders-common-ui'
import { deleteUser } from 'api/users/deleteUser'

const UserDetailsPage: React.FC = () => {
  const [isActiveUser, setIsActiveUser] = useState(true)
  const activeUserData = useAuthenticatedUser()
  const navigate = useNavigate()
  const { updateUser } = useAuth()
  const { setToast } = useToastAlertProvider()
  const { userId = '' } = useParams<{ userId: string }>()
  const { isLoading, data: user, refetch } = useFetchUserById(userId)

  useEffect(() => {
    if (activeUserData.role === 'viewer' && activeUserData.id !== userId) {
      navigate(forbidden, { replace: true })
    } else if (user) {
      setIsActiveUser(user.id === activeUserData.id)
    }
  }, [user, activeUserData])

  const handleClickDelete = async () => {
    try {
      const response = await deleteUser(userId)

      if (response.id) {
        setToast({
          message: 'User deleted succesfully',
          severity: 'success',
        })
        navigate(users)
      }
    } catch {
      setToast({ message: 'User delete failed', severity: 'error' })
      console.error('User delete failed')
    }
  }

  const handleClickSave = async ({
    email,
    nameFirst,
    nameLast,
    role,
  }: {
    email: string
    nameFirst: string
    nameLast: string
    role: Role
  }) => {
    if (user?.id) {
      try {
        const response = await putUser({
          id: user.id,
          email,
          nameFirst,
          nameLast,
          role,
        })

        if (response.id) {
          if (isActiveUser) {
            updateUser(response.nameFirst, response.nameLast)
          }

          setToast({ message: 'User edited successfully', severity: 'success' })

          refetch()
        }
      } catch {
        setToast({ message: 'User edit failed', severity: 'error' })
      }
    } else {
      setToast({ message: 'User edit failed', severity: 'error' })
    }
  }

  if (isLoading === false && user) {
    return (
      <Stack
        data-test-id="location-add-page-container"
        spacing={1}
        p={1}
        flexGrow={1}
      >
        <Paper
          sx={{
            py: 8,
            px: 2,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            alignItems: 'center',
            width: '100%',
            flexGrow: 1,
            justifyContent: 'center',
          }}
        >
          <UserEdit
            email={user.email}
            handleClickDelete={handleClickDelete}
            headingText={
              isActiveUser ? 'My Account' : `${user.nameFirst} ${user.nameLast}`
            }
            showDeleteButton={!isActiveUser}
            showPasswordChangeOption={
              isActiveUser ||
              activeUserData.role === 'admin' ||
              activeUserData.role === 'emc-admin'
            }
            subtitleText={
              isActiveUser
                ? 'Manage Your Account Settings'
                : `Edit ${user.nameFirst}'s account information.`
            }
            handleClickSave={handleClickSave}
            disabledFields={{
              role: isActiveUser || activeUserData.role === 'viewer',
              email: isActiveUser,
            }}
            nameFirst={user.nameFirst}
            nameLast={user.nameLast}
            role={user.role}
            userId={user.id}
          />
        </Paper>
      </Stack>
    )
  } else {
    return <>Loading</>
  }
}

export default UserDetailsPage
