import { apiClient } from 'api/http-common'
import type { APIFunction, GenericErrorResponse } from 'api/http-common'
import { Site, SiteResponse } from './sites-types'
import { mapSiteResposeToSite } from './mapSiteResposeToSite'

type OrganizationSitePatchGatewayIdParams = {
  organizationId: string
  siteId: string
  gatewayId: string
}

export const patchOrganizationSiteGatewayId: APIFunction<
  OrganizationSitePatchGatewayIdParams,
  Site
> = async ({ organizationId, siteId, gatewayId }) => {
  try {
    const response = await apiClient.patch<SiteResponse>(
      `organizations/${organizationId}/sites/${siteId}`,
      {
        gateway_id: gatewayId,
      }
    )

    return mapSiteResposeToSite(response.data)
  } catch (err: unknown) {
    const axiosError = err as GenericErrorResponse
    const { data } = axiosError.response ?? {}

    throw data?.message ?? []
  }
}
