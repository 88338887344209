import { capitalize } from '@mui/material'
import { FixtureType } from 'api/locations/location-types'

export function formatFixtureType(fixtureType: FixtureType) {
  const processedFixtureType = fixtureType.split('_')
  return {
    fixtureType: capitalize(processedFixtureType[0]),
    lampCount:
      processedFixtureType[1] === 'only' ? '0' : processedFixtureType[1],
  }
}
