import { apiClient, APICurriedFunction } from 'api/http-common'
import { Page, PageRequestParams } from 'api/page'
import { mapUserResponseToUser } from './mapUserResponseToUser'
import { User, UserResponse } from './users-types'

export type UsersListRequestParams = PageRequestParams & {
  organization_id: string
}

export const fetchUsersListPaginated: APICurriedFunction<
  UsersListRequestParams,
  Page<User>
> = (requestParams) => async () => {
  const { data: sitesPageResponse } = await apiClient.get<Page<UserResponse>>(
    '/users',
    {
      params: requestParams,
    }
  )

  return {
    ...sitesPageResponse,
    entries: sitesPageResponse.entries.map(mapUserResponseToUser),
  }
}
