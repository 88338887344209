import { apiClient } from 'api/http-common'
import type { APICurriedFunction } from 'api/http-common'
import { Page, PageRequestParams } from 'api/page'
import type { TestHistory, TestHistoryResponse } from './test-types'
import { mapTestHistoryResponseToTestHistory } from 'api/tests'

export type TestHistoryByLocationIdPageRequestParams = PageRequestParams & {
  locationId?: string
  siteId?: string
  organizationId?: string
}

export const fetchTestHistoryByLocationId: APICurriedFunction<
  TestHistoryByLocationIdPageRequestParams,
  Page<TestHistory>
> = (params: TestHistoryByLocationIdPageRequestParams) => async () => {
  const { data } = await apiClient.get<Page<TestHistoryResponse>>(
    `/organizations/${params.organizationId}/sites/${params.siteId}/tests/history`,
    {
      params: {
        location_id: params.locationId,
        page: params.page,
        page_size: params.page_size,
      },
    }
  )

  return {
    ...data,
    entries: data.entries.map(mapTestHistoryResponseToTestHistory),
  }
}
