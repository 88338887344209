import React from 'react'
import { TestHistory } from 'api/tests'
import { TestRescheduled, TestRunning, TestSuccess } from 'flanders-common-ui'
import TestFailure from 'components/atoms/TestFailure'

type TestStatusColumnProps = {
  testHistoryData: TestHistory
  testId?: string
}

const TestStatusColumn: React.FC<TestStatusColumnProps> = ({
  testHistoryData,
  testId,
}) => {
  if (testHistoryData.status === 'failed') {
    return <TestFailure sensorResults={testHistoryData.sensorResults} />
  } else if (testHistoryData.status === 'running') {
    return <TestRunning />
  } else if (
    testHistoryData.status === 'rescheduled' &&
    testHistoryData.rescheduledFor
  ) {
    return <TestRescheduled rescheduledFor={testHistoryData.rescheduledFor} />
  } else {
    return <TestSuccess data-test-id={testId} />
  }
}

export default TestStatusColumn
