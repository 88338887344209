import type { TestHistory, TestHistoryResponse } from './test-types'

export const mapTestHistoryResponseToTestHistory = (
  testHistoryResponse: TestHistoryResponse
): TestHistory => ({
  id: testHistoryResponse.id,
  rescheduledFor: testHistoryResponse.rescheduled_for,
  sensorResults: testHistoryResponse.sensor_results,
  startedAt: testHistoryResponse.started_at,
  status: testHistoryResponse.status,
  testType: testHistoryResponse.test_type,
  scheduledType: testHistoryResponse.scheduled_type,
})
