import { TestResults, TestResultsResponse } from './test-types'

export const mapTestResultsResponseToTestResults = (
  testResultsResponse: TestResultsResponse
): TestResults => ({
  id: testResultsResponse.id,
  endedAt: testResultsResponse.ended_at,
  scheduledFor: testResultsResponse.scheduled_for,
  scheduledType: testResultsResponse.scheduled_type,
  sensorResults: testResultsResponse.sensor_results,
  startedAt: testResultsResponse.started_at,
  status: testResultsResponse.status,
  testType: testResultsResponse.test_type,
})
