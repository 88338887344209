import React, { useEffect, useState } from 'react'
import {
  Button,
  PasswordField,
  useToastAlertProvider,
} from 'flanders-common-ui'
import { Stack } from '@mui/material'
import { PasswordValidations, validatePassword } from 'utils/validate-utils'
import { putUpdatePassword } from 'api/users'
import { useAuthenticatedUser } from 'hooks/useAuth'

export type PasswordEditProps = {
  handleClickSave: () => void
  userId: string
}

const PasswordEdit: React.FC<PasswordEditProps> = ({
  handleClickSave,
  userId,
}) => {
  const { setToast } = useToastAlertProvider()
  const user = useAuthenticatedUser()

  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('')
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)
  const [passwordValidations, setPasswordValidations] =
    useState<PasswordValidations>({})

  const { passwordChanged, passwordLength, passwordConfirmed } =
    passwordValidations

  const newPasswordError = newPassword
    ? passwordChanged?.message ?? passwordLength?.message
    : undefined

  const newPasswordConfirmError =
    !!newPasswordConfirm && passwordConfirmed?.message

  useEffect(() => {
    const resettingPassword =
      user.id !== userId && (user.role === 'emc-admin' || user.role === 'admin')
    const passwordValidations = validatePassword({
      passwordCurrent: resettingPassword
        ? 'resetting-users-password'
        : currentPassword,
      passwordNew: newPassword,
      passwordNewConfirm: newPasswordConfirm,
    })

    const saveDisabled = Object.values(passwordValidations).some(
      (value) => !!value
    )

    setIsSaveDisabled(saveDisabled)
    setPasswordValidations(passwordValidations)
  }, [currentPassword, newPassword, newPasswordConfirm])

  const handleClickSubmit = () => {
    setIsSaveDisabled(true)

    putUpdatePassword({
      userId,
      passwordCurrent: currentPassword,
      passwordNew: newPassword,
    })
      .then(() => {
        setToast({
          message: 'Password updated successfully.',
          severity: 'success',
        })
        handleClickSave()
      })
      .catch(() => {
        setToast({ message: 'Error changing the password.', severity: 'error' })
      })
      .finally(() => {
        setIsSaveDisabled(false)
      })
  }

  return (
    <Stack alignItems="center" spacing={2}>
      {user.id === userId && (
        <PasswordField
          fullWidth
          name="current-password"
          label="Current Password"
          onChange={(
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setCurrentPassword(event.currentTarget.value)
          }}
        />
      )}
      <PasswordField
        fullWidth
        name="new-password"
        label="New Password"
        error={!!newPasswordError}
        helperText={newPasswordError}
        onChange={(
          event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
          setNewPassword(event.currentTarget.value)
        }}
      />
      <PasswordField
        fullWidth
        name="repeat-new-password"
        label="Repeat New Password"
        error={!!newPasswordConfirmError}
        helperText={newPasswordConfirmError}
        onChange={(
          event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
          setNewPasswordConfirm(event.currentTarget.value)
        }}
      />

      <Button disabled={isSaveDisabled} fullWidth onClick={handleClickSubmit}>
        Submit
      </Button>
    </Stack>
  )
}

export default PasswordEdit
