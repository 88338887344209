import { apiClient } from 'api/http-common'
import type { APIFunction, GenericErrorResponse } from 'api/http-common'
import type { Role, RoleResponse, User, UserResponse } from './users-types'
import { mapUserResponseToUser } from './mapUserResponseToUser'

type PutUserRequestParams = {
  id: string
  email: string
  nameFirst: string
  nameLast: string
  role: Role
}

export const putUser: APIFunction<PutUserRequestParams, User> = async ({
  id,
  email,
  nameFirst,
  nameLast,
  role,
}) => {
  //Convert client-side role to server-side role
  const mappedRole: RoleResponse = role === 'emc-admin' ? 'emc_admin' : role

  try {
    const response = await apiClient.put<UserResponse>(`users/${id}`, {
      email,
      first_name: nameFirst,
      last_name: nameLast,
      role: mappedRole,
    })

    return mapUserResponseToUser(response.data)
  } catch (err: unknown) {
    const axiosError = err as GenericErrorResponse
    const { data } = axiosError.response ?? {}

    throw data?.message ?? []
  }
}
