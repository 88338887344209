import React from 'react'
import { Paper, Stack } from '@mui/material'
import OrganizationEdit from 'components/organisms/forms/OrganizationEdit'
import { OrganizationPostData, postOrganization } from 'api/organizations'
import { useToastAlertProvider } from 'flanders-common-ui'
import { useNavigate } from 'react-router-dom'
import { organizations } from 'routes/routes'

const OrganizationsAddPage: React.FC = () => {
  const { setToast } = useToastAlertProvider()
  const navigate = useNavigate()

  const onSubmit = async (organizationData: OrganizationPostData) => {
    try {
      const response = await postOrganization(organizationData)

      if (response.id) {
        setToast({
          message: 'Organization added succesfully',
          severity: 'success',
        })
        navigate(organizations)
      }
    } catch {
      setToast({ message: 'Organization add failed', severity: 'error' })
      console.error('Organization add failed')
    }
  }

  return (
    <Stack
      component={Paper}
      spacing={1}
      padding={'25px'}
      px={2}
      pt="20px"
      alignItems={'center'}
    >
      <OrganizationEdit
        handleClickSubmit={onSubmit}
        headingText="Add Organization"
        subtitleText="Tell us about the organization."
      />
    </Stack>
  )
}

export default OrganizationsAddPage
