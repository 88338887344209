import { TestCycleResultsResponse, TestCycleResults } from 'api/tests'
import { mapTestCycleResultsLocationResponseToTestCycleResultsLocation } from './mapTestCycleResultsLocationResponseToTestCycleResultsLocation'

export const mapTestCyclesResultResponseToTestCyclesResult = (
  data: TestCycleResultsResponse
): TestCycleResults => {
  return {
    id: data.id,
    gatewayId: data.gateway_id,
    testCycleLocations: data.test_cycle_locations.map((testCycleLocation) =>
      mapTestCycleResultsLocationResponseToTestCycleResultsLocation(
        testCycleLocation
      )
    ),
    beginningAt: data.beginning_at,
  }
}
