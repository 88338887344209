import React from 'react'
import { Paper, Stack } from '@mui/material'
import OrganizationEdit from 'components/organisms/forms/OrganizationEdit'
import {
  deleteOrganization,
  Organization,
  OrganizationPostData,
  putOrganization,
} from 'api/organizations'
import { useToastAlertProvider } from 'flanders-common-ui'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { organizations } from 'routes/routes'
import moment from 'moment'

const OrganizationEditPage: React.FC = () => {
  const { setToast } = useToastAlertProvider()
  const navigate = useNavigate()
  const { organizationId = '' } = useParams<{ organizationId: string }>()
  const location = useLocation()

  const { organization: organizationData }: { organization: Organization } =
    location.state

  const onSubmit = async (organizationData: OrganizationPostData) => {
    try {
      const response = await putOrganization({
        ...organizationData,
        id: organizationId,
      })

      if (response.id) {
        setToast({
          message: 'Organization edited succesfully',
          severity: 'success',
        })
        navigate(organizations)
      }
    } catch {
      setToast({ message: 'Organization edit failed', severity: 'error' })
      console.error('Organization edit failed')
    }
  }

  const onDelete = async () => {
    try {
      const response = await deleteOrganization(organizationId)

      if (response.id) {
        setToast({
          message: 'Organization deleted succesfully',
          severity: 'success',
        })
        navigate(organizations)
      }
    } catch {
      setToast({ message: 'Organization delete failed', severity: 'error' })
      console.error('Organization delete failed')
    }
  }

  return (
    <Stack
      component={Paper}
      spacing={1}
      padding={'25px'}
      px={2}
      pt="20px"
      alignItems={'center'}
    >
      <OrganizationEdit
        handleClickSubmit={onSubmit}
        handleClickDelete={onDelete}
        headingText="Edit Organization"
        subtitleText="Make changes to the organization"
        address1={organizationData?.address1}
        address2={organizationData?.address2}
        anniversaryDate={
          organizationData?.anniversaryDate
            ? moment(organizationData?.anniversaryDate)
            : null
        }
        city={organizationData?.city}
        name={organizationData?.name}
        state={organizationData?.state ?? 'AL'}
        postalCode={organizationData?.postalCode}
      />
    </Stack>
  )
}

export default OrganizationEditPage
