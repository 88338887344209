type NodeStatus = 'offline' | 'online' | 'sensor_error'

function isNodeStatus(status: unknown): status is NodeStatus {
  return (
    status !== null &&
    typeof status === 'string' &&
    ['offline', 'online', 'sensor_error'].includes(status)
  )
}

type Node = {
  id: string
  location_id: string
  has_valid_sensors: boolean
  serial_number: string
  status: NodeStatus
  priority_status: NodeStatus
}

export type { Node, NodeStatus }
export { isNodeStatus }
