import { useCallback, useMemo, useState } from 'react'
import { getValueFromLocalStorage, setValueOnLocalStorage } from 'utils'

export function useLocalStorage<T = Record<string, unknown>>(
  key: string,
  defaultValue?: T
): [value: T | undefined, setValue: (newValue?: T) => void] {
  const [storedValue, setStoredValue] = useState(
    getValueFromLocalStorage<T>(key, defaultValue)
  )

  const setValue = useCallback(
    (newValue?: T) => {
      setValueOnLocalStorage<T>(key, newValue, setStoredValue)
    },
    [key]
  )

  return useMemo(() => [storedValue, setValue], [setValue, storedValue])
}
