import React from 'react'
import {
  Divider,
  Grid,
  Paper,
  Skeleton,
  Stack,
  useMediaQuery,
} from '@mui/material'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import {
  AboveFoldLayout,
  Button,
  Fixture,
  LocationStatus,
  TableBanner,
  theme,
  Typography,
  StatusChart,
} from 'flanders-common-ui'
import { FindInPage } from '@mui/icons-material'
import { GridColDef, gridStringOrNumberComparator } from '@mui/x-data-grid'
import {
  Location,
  useFetchLocationsListPaginated,
  useFetchSiteOverviewById,
} from 'api/locations'
import { RouteLabels } from 'routes/route-labels'
import DataGrid from 'components/molecules/DataGrid'
import { sites } from 'routes/routes'
import { locationFixtureTypeComparator } from 'utils/location-comparators'
import { formatFixtureType } from 'utils/formatFixtureType'
import TestFailure from 'components/atoms/TestFailure'
import UpcomingTestsPanel from 'components/organisms/tests/UpcomingTestsPanel'
import ComplianceReportPanel from 'components/organisms/compliance-reports/ComplianceReportPanel'
import { useFetchSiteById } from 'api/sites'
import {
  TestCycle,
  useFetchTestCycles,
  useFetchUpcomingTestsByLocationId,
} from 'api/tests'
import SiteDetailsPanel from 'components/molecules/SiteDetailsPanel'
import SiteHealthyDisplay from './SiteHealthyDisplay'
import { useActiveOrganizationContext } from 'hooks/useActiveOrganization'

const getStatus = (location: Location) => {
  const nodeStatus = location?.node?.priority_status
  const verifiedAt = location.visuallyVerifiedAt
  const lastTest = location?.lastTest?.status

  return verifiedAt === null
    ? 'unverified'
    : nodeStatus === 'offline'
    ? 'offline'
    : lastTest === 'failed'
    ? 'failed'
    : null
}

function useColumns(): GridColDef<Location>[] {
  const columns: GridColDef<Location>[] = [
    {
      field: 'name',
      headerName: 'Location Name',
      flex: 3,
      renderCell: ({ row: location }) => {
        return (
          <NavLink
            key={location.id}
            to={`locations/${location.id}`}
            state={location}
          >
            {location.name}
          </NavLink>
        )
      },
    },
    {
      field: 'fixture.fixtureType',
      headerName: 'Fixture Type',
      flex: 1,
      sortComparator: locationFixtureTypeComparator,
      renderCell: ({ row: location }) => {
        const fixtureType = location.fixture?.fixtureType

        if (fixtureType === null || fixtureType === undefined) {
          return <span>--</span>
        }

        const formattedFixtureType = formatFixtureType(fixtureType)

        return (
          <Fixture
            fixtureType={formattedFixtureType.fixtureType}
            lampCount={formattedFixtureType.lampCount}
          />
        )
      },
    },
    {
      field: 'fixture.battery_model_number',
      headerName: 'Battery Part #',
      flex: 1,
      valueGetter: ({ row: location }) => {
        const batteryModelNumber = location.fixture?.batteryModelNumber

        return typeof batteryModelNumber === 'string'
          ? batteryModelNumber
          : '--'
      },
    },
    {
      field: 'issue_type',
      headerName: 'Issue',
      flex: 2,
      valueGetter: (params) => params.row,
      sortComparator: (
        location1: Location,
        location2: Location,
        param1,
        param2
      ) => {
        const status1 = getStatus(location1)
        const status2 = getStatus(location2)

        return gridStringOrNumberComparator(status1, status2, param1, param2)
      },
      renderCell: ({ row: location }) => {
        const status = getStatus(location)
        const locationId: string = location.id

        switch (status) {
          case 'unverified': {
            return (
              <LocationStatus
                data-test-id={`location-requiring-attention-status-chip-${locationId}`}
                status="Unverified"
                description="Verification Required"
              />
            )
          }

          case 'offline': {
            return (
              <LocationStatus
                data-test-id={`location-requiring-attention-status-chip-${locationId}`}
                status="Offline"
                description="No Connection"
              />
            )
          }

          case 'failed': {
            return (
              <TestFailure sensorResults={location?.lastTest?.sensorResults} />
            )
          }

          default: {
            return <span>--</span>
          }
        }
      },
    },
  ]

  return columns
}

const openInNewTab = (url: string) => {
  window.open(url, '_blank', 'noopener,noreferrer')
}

const SiteDetailsPage: React.FC = () => {
  const navigate = useNavigate()
  const { siteId = '' } = useParams<{ siteId: string }>()
  const { activeOrganization } = useActiveOrganizationContext()
  const { isLoading: isLoadingSiteDetails, data: siteData } = useFetchSiteById({
    organizationId: activeOrganization?.id ?? '',
    siteId: siteId,
  })

  const { data: upcomingTestsData, isLoading: isLoadingUpcomingTests } =
    useFetchUpcomingTestsByLocationId({
      locationId: 'sitewide',
      organizationId: activeOrganization?.id ?? '',
      page: 1,
      page_size: 100,
      siteId: siteId,
    })

  const { isLoading: isLoadingTestCycles, data: testCyclesResponse } =
    useFetchTestCycles({
      organizationId: activeOrganization?.id ?? '',
      siteId: siteId,
    })

  const columns = useColumns()

  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))

  const { isLoading: isLoadingOverview, data: locationsOverviewData } =
    useFetchSiteOverviewById({
      organizationId: activeOrganization?.id ?? '',
      siteId: siteId,
    })

  const healthy = locationsOverviewData?.healthy ?? 0
  const failing = locationsOverviewData?.failing ?? 0
  const offline = locationsOverviewData?.offline ?? 0
  const unverified = locationsOverviewData?.unverified ?? 0
  const running = locationsOverviewData?.running ?? 0
  const sensorErrors = locationsOverviewData?.sensorErrors ?? 0
  const requireAttention = failing + offline + unverified + sensorErrors
  const totalLocationCount = healthy + running + requireAttention
  const systemHealthy = requireAttention === 0 && running === 0

  const handleComplianceReportGeneratorSubmit = (
    start: TestCycle,
    end: TestCycle
  ) => {
    openInNewTab(
      `/reports/compliance?site_id=${siteId}&beginning_cycle_id=${start.id}&ending_cycle_id=${end.id}`
    )
  }

  return isLoadingSiteDetails || isLoadingOverview || !siteData ? (
    <Skeleton
      variant="rectangular"
      width="100%"
      height={400}
      animation="wave"
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      Loading...
    </Skeleton>
  ) : (
    <Grid container spacing={1}>
      <AboveFoldLayout
        breadcrumbRoutes={[RouteLabels[1]]}
        heading={siteData.name}
      />

      <Grid container item spacing={2}>
        <Grid item xs={12} lg={8}>
          <Stack component={Paper} spacing={2} px={2} pt="20px" mb={3}>
            <Typography variant="h3" weight="bold">
              Site Overview
            </Typography>
            <Divider variant="fullWidth" />
            {systemHealthy ? (
              <SiteHealthyDisplay totalLocationCount={totalLocationCount} />
            ) : (
              <>
                <Grid container rowGap={3}>
                  <StatusChart
                    healthy={healthy}
                    requireAttention={requireAttention}
                    running={running}
                    targetName="LOCATIONS"
                    totalCount={totalLocationCount}
                    renderBelowChart={
                      <Button
                        sx={{ marginBottom: 3 }}
                        endIcon={<FindInPage />}
                        onClick={() => {
                          navigate(`${sites}/${siteId}/locations`)
                        }}
                      >
                        View All Locations
                      </Button>
                    }
                  />
                </Grid>
                <Stack width="100%">
                  <TableBanner
                    requireAttentionCount={requireAttention}
                    targetNamePlural="Locations"
                    targetNameSingular="Location"
                  />
                  <DataGrid<Location>
                    columns={columns}
                    dataId="locations-requiring-attention-table"
                    requestExtraParams={{
                      organizationId: activeOrganization?.id,
                      requiringAttention: true,
                    }}
                    useFetchPaginatedData={useFetchLocationsListPaginated}
                  />
                </Stack>
              </>
            )}
          </Stack>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Stack spacing={2} maxWidth={isLgUp ? 375 : 'auto'}>
            <ComplianceReportPanel
              isLoading={isLoadingTestCycles}
              testCycles={testCyclesResponse?.data}
              onSubmit={handleComplianceReportGeneratorSubmit}
            />

            <UpcomingTestsPanel
              body="Tests scheduled in the next 30 days"
              data={upcomingTestsData}
              heading="Upcoming Site-Wide Tests"
              isLoading={isLoadingUpcomingTests}
              title="Upcoming Tests"
            />

            <SiteDetailsPanel site={siteData} />
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SiteDetailsPage
