import React from 'react'
import { Grid, Stack } from '@mui/material'
import type { LocationFixture } from 'api/locations'
import type { Node } from 'api/nodes'
import {
  DetailsPanel,
  FixtureTypeImage,
  theme,
  Typography,
  Attribute,
} from 'flanders-common-ui'

export type LocationDetailsPanelType = {
  locationFixture: LocationFixture | null
  locationNode: Node | null
  notes: string | null
}

const LocationDetailsPanel: React.FC<LocationDetailsPanelType> = ({
  locationFixture,
  locationNode,
  notes,
}) => {
  return (
    <DetailsPanel heading="Location Details">
      {locationFixture?.fixtureType && (
        <Stack
          alignItems={'center'}
          borderBottom={'solid 1px'}
          borderColor={theme.palette.UI.main}
          padding={2}
          position="relative"
        >
          <FixtureTypeImage fixtureType={locationFixture.fixtureType} />
        </Stack>
      )}

      <Grid container flexDirection={'column'}>
        <Stack
          flex={1}
          borderBottom={'solid 1px'}
          borderColor={theme.palette.UI.main}
          marginBottom={2}
          paddingBottom={2}
        >
          <Grid item paddingBottom={1}>
            <Typography weight="bold" variant="h4">
              Fixture Battery
            </Typography>
          </Grid>

          <Grid
            alignItems="center"
            display="flex"
            flex={1}
            flexDirection="row"
            gap={1}
            justifyContent="center"
          >
            <Attribute
              label="Part Number"
              value={locationFixture?.batteryModelNumber}
            />
            <Attribute label="Voltage" value={locationFixture?.voltage} />
          </Grid>
        </Stack>

        <Stack
          flex={1}
          borderBottom={'solid 1px'}
          borderColor={theme.palette.UI.main}
          marginBottom={2}
          paddingBottom={2}
        >
          <Grid item paddingBottom={1}>
            <Typography weight="bold" variant="h4">
              Node Information
            </Typography>
          </Grid>

          <Grid
            alignItems="center"
            display="flex"
            flex={1}
            flexDirection="row"
            gap={1}
            justifyContent="center"
          >
            <Attribute
              label="Serial Number"
              value={locationNode?.serial_number}
            />
          </Grid>
        </Stack>

        <Stack flex={1}>
          <Grid item paddingBottom={1}>
            <Typography weight="bold" variant="h4">
              Notes
            </Typography>
          </Grid>

          <Grid
            alignItems="center"
            display="flex"
            flex={1}
            flexDirection="row"
            gap={1}
            justifyContent="center"
          >
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <Typography variant="body" color={theme.palette.grey[900]}>
                {notes !== null && notes.length ? notes : 'N/A'}
              </Typography>
            </Grid>
          </Grid>
        </Stack>
      </Grid>
    </DetailsPanel>
  )
}

export default LocationDetailsPanel
