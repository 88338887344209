import {
  apiClient,
  APICurriedFunction,
  GenericErrorResponse,
} from 'api/http-common'
import { mapSiteResposeToSite } from './mapSiteResposeToSite'
import { Site, SiteResponse } from './sites-types'

export type SiteDetailsRequestParams = {
  organizationId: string
  siteId: string
}

export const fetchSiteById: APICurriedFunction<
  SiteDetailsRequestParams,
  Site
> = (params) => async () => {
  try {
    const response = await apiClient.get<SiteResponse>(
      `/organizations/${params.organizationId}/sites/${params.siteId}`
    )

    return mapSiteResposeToSite(response.data)
  } catch (error) {
    const axiosError = error as GenericErrorResponse
    const { data } = axiosError.response ?? {}
    const defaultErrorMessage = 'Error fetching Site data'

    console.error(defaultErrorMessage, error)

    throw data?.message ?? [defaultErrorMessage]
  }
}
